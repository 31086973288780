import React, {useRef, useState} from 'react';
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {Button, Form, Image, Input, InputNumber, message, Modal, Select, Space} from "antd";
import {addRoomHotel, deleteHotelRoom, getList, getRoomList, updateHotelRoom} from "../../../services/HotelService";
import API from "../../../data/API";
import GSTable from "../../../components/GSTable";
import UploadImgFormItem from "../../../components/UploadImgFormItem";

const Index: React.FC<any> = (props: any) => {
  const actionRef = useRef<ActionType>()
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [search, setSearch] = useState('');
  const [addVisible, setAddVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const columns = [
    {
      title: '房间名称',
      dataIndex: 'title',
      width: 200,
      key: 'title',
    },
    {
      title: '图片',
      dataIndex: 'img',
      width: 100,
      key: 'img',
      render: (_, record) => <div>
        {
          <Image src={(record.img || '').split(',')[0]} style={{width: 100, height: 100}}/>
        }
      </div>
    },

    {
      title: '介绍',
      width: 150,
      ellipsis: true,
      dataIndex: 'intro',
      key: 'intro',
    },
    {
      title: '价格',
      dataIndex: 'price',
      width: 80,
      key: 'price',
    },
    {
      title: '面积',
      width: 80,
      dataIndex: 'area',
      key: 'area',
    },
    {
      title: '楼层',
      width: 80,
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: '人数',
      width: 100,
      dataIndex: 'people',
      key: 'people',
    },
    {
      title: '创建时间',
      width: 200,
      dataIndex: 'createTime',
      valueType: 'dateTime',
      key: 'createTime',
    },
    {
      title: '操作',
      width: 100,
      fixed: 'right',
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <a onClick={() => {
            record.wash= (record.wash || '').split(',');
            record.convenience = (record.convenience || '').split(',');
            record.childrenStr = (record.childrenStr || '').split(',');
            record.landscape = (record.landscape || '').split(',');
            record.washroom = (record.washroom || '').split(',');
            record.food = (record.food || '').split(',');
            record.equipment = (record.equipment || '').split(',');
            updateForm.setFieldsValue({...record})
            setUpdateVisible(true)
          }}>编辑</a>
          <a onClick={() => {
            Modal.confirm({
              title: '你确定要删除' + record.title + '吗？',
              okType: 'danger',
              onOk: async () => {
                const data: any = {
                  id: record.id
                };
                const res = await deleteHotelRoom(data);
                if (res.err === 0) {
                  message.success('删除成功');
                  actionRef.current.reload();
                } else {
                  message.warning(res.msg);
                }
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }}>删除</a>
        </Space>
      ),
    },
  ];
  const tableSearchBarRender = () => [
    <Input placeholder={'名称'} onChange={(event: any) => {
      setSearch(event.target.value)
    }}/>,
    <Button type="primary" onClick={() => {
      if (actionRef.current) {
        actionRef.current.reload()
      }
    }}>查询</Button>
  ];
  const addItem = async () => {
    let data = await addForm.validateFields();
    console.log('data', data.imgs);

    let imgs = data.img;
    let tempImgs = (imgs || '').split(',');
    let httpImgs: any = [];
    tempImgs.map(imgItem => {
      if (!imgItem.includes('http')) {
        httpImgs.push(API.cdnRootPath + imgItem);
      } else {
        httpImgs.push(imgItem);
      }
    })
    data.img = httpImgs.toString();
    data.wash= data.wash.toString();
    data.convenience = data.convenience.toString();
    data.childrenStr = data.childrenStr.toString();
    data.landscape = data.landscape.toString();
    data.washroom = data.washroom.toString();
    data.food = data.food.toString();
    data.equipment = data.equipment.toString();

    let rsp = await addRoomHotel({hotelId: props.hotelId, ...data});
    if (rsp.err === 0) {
      setAddVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  const updateItem = async () => {
    let data = await updateForm.validateFields();
    let imgs = data.img;
    let tempImgs = (imgs || '').split(',');
    let httpImgs: any = [];
    tempImgs.map(imgItem => {
      if (!imgItem.includes('http')) {
        httpImgs.push(API.cdnRootPath + imgItem);
      } else {
        httpImgs.push(imgItem);
      }
    })
    data.img = httpImgs.toString();
    data.img = httpImgs.toString();
    data.wash= data.wash.toString();
    data.convenience = data.convenience.toString();
    data.childrenStr = data.childrenStr.toString();
    data.landscape = data.landscape.toString();
    data.washroom = data.washroom.toString();
    data.food = data.food.toString();
    data.equipment = data.equipment.toString();
    let rsp = await updateHotelRoom({hotelId: props.hotelId, ...data});
    if (rsp.err === 0) {
      setUpdateVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  return <>
    <GSTable
      columns={columns}
      actionRef={actionRef}
      rowKey={"id"}
      searchBarRender={tableSearchBarRender()}
      operationsBarRender={[
        <Button type={"primary"} onClick={() => {
          addForm.resetFields()
          setAddVisible(true)
        }}>添加</Button>
      ]}
      request={async (params: any = {}, sort: any, filter: any) => {
        const res = await getRoomList({
          page: params.current,
          size: params.pageSize,
          hotelId: props.hotelId,
          name: search,
        });
        if (res.err !== 0) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: res.data,
          success: true,
        };
      }}
    />
    <Modal destroyOnClose={true} visible={addVisible} title={'添加房间'} onCancel={() => {
      setAddVisible(false)
    }} onOk={addItem}>
      <Form form={addForm} labelCol={{span: 6}}>
        <Form.Item rules={[{required: true}]} name={'title'} label={'房间名称'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'img'} label={'图片'}>
          <UploadImgFormItem multiple={true} hiddenImgCorp={true}
                             listType={'picture-card'}
                             heightRatio={1}
                             widthRatio={1}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'intro'} label={'介绍'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'general'} label={'客房概况'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'policy'} label={'费用政策'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'checkin'} label={'入住政策'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'price'} label={'价格'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'floor'} label={'楼层'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'area'} label={'面积'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'people'} label={'人数'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'hasWindow'} label={'窗户'}>
          <Select>
            <Select.Option value={false}>无窗</Select.Option>
            <Select.Option value={true}>有窗</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'smokingAllowed'} label={'吸烟'}>
          <Select>
            <Select.Option value={false}>不可吸烟</Select.Option>
            <Select.Option value={true}>可以吸烟</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'wash'} label={'洗浴用品'}>
          <Select mode={'tags'}>
            <Select.Option value={'牙刷'} >牙刷</Select.Option>
            <Select.Option value={'牙膏'} >牙膏</Select.Option>
            <Select.Option value={'沐浴露'} >沐浴露</Select.Option>
            <Select.Option value={'洗发水'} >洗发水</Select.Option>
            <Select.Option value={'护发素'} >护发素</Select.Option>
            <Select.Option value={'香皂'} >香皂</Select.Option>
            <Select.Option value={'浴帽'} >浴帽</Select.Option>
            <Select.Option value={'梳子'} >梳子</Select.Option>
            <Select.Option value={'剃须刀'} >剃须刀</Select.Option>
            <Select.Option value={'毛巾'} >毛巾</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'convenience'} label={'便利设备'}>
          <Select mode={'tags'}>
            <Select.Option value={'房间内高速上网'} >房间内高速上网</Select.Option>
            <Select.Option value={'客房Wi-Fi免费'} >客房Wi-Fi免费</Select.Option>
            <Select.Option value={'衣柜/衣橱'} >衣柜/衣橱</Select.Option>
            <Select.Option value={'熨烫设备'} >熨烫设备</Select.Option>
            <Select.Option value={'暖气'} >暖气</Select.Option>
            <Select.Option value={'茶几'} >茶几</Select.Option>
            <Select.Option value={'休闲椅'} >休闲椅</Select.Option>
            <Select.Option value={'开夜床'} >开夜床</Select.Option>
            <Select.Option value={'洗衣机'} >洗衣机</Select.Option>
            <Select.Option value={'床具毯子或被子'} >床具毯子或被子</Select.Option>
            <Select.Option value={'洗衣用品'} >洗衣用品</Select.Option>
            <Select.Option value={'衣架'} >衣架</Select.Option>
            <Select.Option value={'雨伞'} >雨伞</Select.Option>
            <Select.Option value={'多种规格电源插座'} >多种规格电源插座</Select.Option>
            <Select.Option value={'110V电压插座'} >110V电压插座</Select.Option>
            <Select.Option value={'220V电压插座'} >220V电压插座</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'childrenStr'} label={'儿童设施'}>
          <Select mode={'tags'}>
            <Select.Option value={'儿童洗漱用品'} >儿童洗漱用品</Select.Option>
            <Select.Option value={'儿童拖鞋'} >儿童拖鞋</Select.Option>
            <Select.Option value={'儿童马桶圈'} >儿童马桶圈</Select.Option>
            <Select.Option value={'儿童玩具'} >儿童玩具</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'landscape'} label={'景观'}>
          <Select mode={'tags'}>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'washroom'} label={'浴室'}>
          <Select mode={'tags'}>
            <Select.Option value={'浴缸'} >浴缸</Select.Option>
            <Select.Option value={'独立淋浴间'} >独立淋浴间</Select.Option>
            <Select.Option value={'独立卫生间'} >独立卫生间</Select.Option>
            <Select.Option value={'吹风机'} >吹风机</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'food'} label={'食品饮品'}>
          <Select mode={'tags'}>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'equipment'} label={'酒店设施'}>
          <Select mode={'tags'}>
          </Select>
        </Form.Item>
        <Form.Item initialValue={1} rules={[{required: true}]} name={'sort'} label={'排序'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
      </Form>
    </Modal>
    <Modal destroyOnClose={true} visible={updateVisible} title={'编辑房间'} onCancel={() => {
      setUpdateVisible(false)
    }} onOk={updateItem}>
      <Form form={updateForm} labelCol={{span: 6}}>
        <Form.Item rules={[{required: true}]} hidden={true} name={'id'} label={'id'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'title'} label={'房间名称'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'img'} label={'图片'}>
          <UploadImgFormItem multiple={true} hiddenImgCorp={true}
                             listType={'picture-card'}
                             heightRatio={1}
                             widthRatio={1}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'intro'} label={'介绍'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'general'} label={'客房概况'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'policy'} label={'费用政策'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'checkin'} label={'入住政策'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'price'} label={'价格'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'floor'} label={'楼层'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'area'} label={'面积'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'people'} label={'人数'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'hasWindow'} label={'窗户'}>
          <Select>
            <Select.Option value={false}>无窗</Select.Option>
            <Select.Option value={true}>有窗</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'smokingAllowed'} label={'吸烟'}>
          <Select>
            <Select.Option value={false}>不可吸烟</Select.Option>
            <Select.Option value={true}>可以吸烟</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'wash'} label={'洗浴用品'}>
          <Select mode={'tags'}>
            <Select.Option value={'牙刷'} >牙刷</Select.Option>
            <Select.Option value={'牙膏'} >牙膏</Select.Option>
            <Select.Option value={'沐浴露'} >沐浴露</Select.Option>
            <Select.Option value={'洗发水'} >洗发水</Select.Option>
            <Select.Option value={'护发素'} >护发素</Select.Option>
            <Select.Option value={'香皂'} >香皂</Select.Option>
            <Select.Option value={'浴帽'} >浴帽</Select.Option>
            <Select.Option value={'梳子'} >梳子</Select.Option>
            <Select.Option value={'剃须刀'} >剃须刀</Select.Option>
            <Select.Option value={'毛巾'} >毛巾</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'convenience'} label={'便利设备'}>
          <Select mode={'tags'}>
            <Select.Option value={'房间内高速上网'} >房间内高速上网</Select.Option>
            <Select.Option value={'客房Wi-Fi免费'} >客房Wi-Fi免费</Select.Option>
            <Select.Option value={'衣柜/衣橱'} >衣柜/衣橱</Select.Option>
            <Select.Option value={'熨烫设备'} >熨烫设备</Select.Option>
            <Select.Option value={'暖气'} >暖气</Select.Option>
            <Select.Option value={'茶几'} >茶几</Select.Option>
            <Select.Option value={'休闲椅'} >休闲椅</Select.Option>
            <Select.Option value={'开夜床'} >开夜床</Select.Option>
            <Select.Option value={'洗衣机'} >洗衣机</Select.Option>
            <Select.Option value={'床具毯子或被子'} >床具毯子或被子</Select.Option>
            <Select.Option value={'洗衣用品'} >洗衣用品</Select.Option>
            <Select.Option value={'衣架'} >衣架</Select.Option>
            <Select.Option value={'雨伞'} >雨伞</Select.Option>
            <Select.Option value={'多种规格电源插座'} >多种规格电源插座</Select.Option>
            <Select.Option value={'110V电压插座'} >110V电压插座</Select.Option>
            <Select.Option value={'220V电压插座'} >220V电压插座</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'childrenStr'} label={'儿童设施'}>
          <Select mode={'tags'}>
            <Select.Option value={'儿童洗漱用品'} >儿童洗漱用品</Select.Option>
            <Select.Option value={'儿童拖鞋'} >儿童拖鞋</Select.Option>
            <Select.Option value={'儿童马桶圈'} >儿童马桶圈</Select.Option>
            <Select.Option value={'儿童玩具'} >儿童玩具</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'landscape'} label={'景观'}>
          <Select mode={'tags'}>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'washroom'} label={'浴室'}>
          <Select mode={'tags'}>
            <Select.Option value={'浴缸'} >浴缸</Select.Option>
            <Select.Option value={'独立淋浴间'} >独立淋浴间</Select.Option>
            <Select.Option value={'独立卫生间'} >独立卫生间</Select.Option>
            <Select.Option value={'吹风机'} >吹风机</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'food'} label={'食品饮品'}>
          <Select mode={'tags'}>
          </Select>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'equipment'} label={'酒店设施'}>
          <Select mode={'tags'}>
          </Select>
        </Form.Item>
        <Form.Item initialValue={1} rules={[{required: true}]} name={'sort'} label={'排序'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
      </Form>
    </Modal>
  </>
}
export default Index;
