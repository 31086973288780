import Api from "../data/API";
import { Button, Upload } from "antd";
import React from "react";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons/lib";
import Resizer from 'react-image-file-resizer';
import { RcFile } from 'antd/lib/upload';

const URLS = {
    PRIVATE: Api.apiRootPath + "/api/picture/upload_qiniu",
    PUBLIC: Api.apiRootPath + "/api/picture/upload_qiniu_two"
};

export default function CustomUploadImage(props: any) {
    const text = props.text ? props.text : "选择图片";
    const cdn = props.cdn ? URLS[props.cdn.toUpperCase()] : URLS.PUBLIC;
    const listType = props.listType ? props.listType : "picture";

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );
    const resizeFile = (file: RcFile) => {
        console.log('file:::::', file.size <= 200*1024)
        if (file.size <= 200*1024) {
            return new Promise((resolve) => {
                resolve(file);
            });
        }
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'jpeg',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file',
                480,
                640
            );
        });
    };
    const transformFile = (file: RcFile) => {
        return resizeFile(file)
            .then((url: any) => {
                console.log('文件大小：：：', file.size, url.size);
                return url;
            })
            .catch((e: any) => {
                console.log('发生异常', e);
                return file;
            });
    };
    const render = props.render ? props.render : uploadButton;
    const { ...rest } = props;
    return (
        <>
            <Upload
                name="file"
                transformFile={transformFile}
                listType={listType}
                data={{ wuye_uuid: localStorage.getItem("wyUuid"), ...props.data }}
                action={cdn}
                accept={".jpg,.png"}
                {...rest}
            >
                {render}
            </Upload>
        </>

    );
}
