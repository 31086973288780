import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Upload} from 'antd';
import Api from '../../src/data/API';
import {RcFile} from 'antd/lib/upload';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons/lib';
import ImgCrop from 'antd-img-crop';
import DAL from '../data/DAL';

// import Resizer from "react-image-file-resizer";

interface UploadImgFormItemProps {
  value?: string;
  onChange?: (value: string) => void;
  multiple: boolean;
  listType?: string;
  disabled?: boolean;
  isFace?: boolean;
  hiddenImgCorp?: boolean;
  widthRatio?: number;
  heightRatio?: number;

}


const UploadImgFormItem: React.FC<UploadImgFormItemProps> = (props: any) => {
  const maxFileSizeLimit = 30; // 单位M
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [dateKey, setdateKey] = useState(new Date().getTime().toString())
  let defaultTotal = 0;
  let errorTotal = 0;
  let uploadTotal = 0;
  useEffect(() => {
    console.log("value::::", props.value, props.widthRatio, props.heightRatio);

    if (props.value) {
      let fileUrl: string = props.value;
      let tempFileList: any = [];
      if (DAL.dal.isNotNull(fileUrl)) {
        fileUrl.split(',').map((item) => {
          let file: any = {
            uid: new Date().getTime(),
            name: '照片',
            status: 'done',
            url: item.includes('http') ? item : Api.cdnRootPath + item,
            response: {
              err: 0,
              url: item.includes('http') ? item : Api.cdnRootPath + item,
            },
          };
          tempFileList.push(file);
        });
      }
      defaultTotal = tempFileList.length
      console.log('tempFileList', tempFileList)
      setFileList([...tempFileList]);
    } else {
      console.log('这里？')
      defaultTotal = 0
      setFileList([])
    }
    errorTotal = 0
    uploadTotal = 0

    setdateKey(new Date().getTime().toString())
  }, [props.value]);

  const triggerChange = (value?: string) => {
    props.onChange?.(value || '');
  };

  const onChangeFile = (info: any) => {
    const {status} = info.file;
    if (status == undefined) {
      return;
    }
    console.log('UploadFile.onChange: ', info);
    // 限制最多传一个文件

    if (status == 'uploading') {
      console.log("uploading", info.file, info.fileList);
    }
    if (status === 'removed') {
      let url = info.file.url;
      let name = []
      fileList.map(file => {
        console.log("imageUrls", file, file.response.url.includes(url))

        if (!(file.response.url.includes(url))) {
          if (file.response.url.includes(Api.cdnRootPath)) {
            name.push(file.response.url.slice(file.response.url.lastIndexOf("/") + 1));

          } else {
            name.push(file.response.url);

          }
        }
      })
      console.log("imageUrls::", fileList, name, url);
      triggerChange(name.toString());
      return
    }
    if (!props.multiple) {
      let fileList: any = [...info.fileList];
      fileList = fileList.slice(-1);
      setFileList(fileList);
    } else {
      setFileList([...info.fileList]);
    }

    if (status === 'done') {
      let name: any = [];
      console.log('上传文件列表：fileList, ', info.fileList)
      info.fileList.map((file: any) => {
        console.log('上传文件列表：file', file, file.response);
        if (info.status === undefined) {
          info.status = 'error';
          info.response = '';
        }
        if (file.status == 'done') {
          if (file.response.url.includes(Api.cdnRootPath)) {

            name.push(file.response.url.slice(file.response.url.lastIndexOf("/") + 1));
          } else {
            name.push(file.response.url);
          }
        }
      });
      triggerChange(name.toString());
    } else if (status === 'error') {
      message.error(`上传失败`);
    }
  };

  /**
   * 检查文件尺寸
   */
  const beforeUploadFile = (file: RcFile, fileList: RcFile[]): boolean => {
    const fileSize = file.size / 1024 / 1024;
    console.log('UploadFile.beforeUpload: ', file, maxFileSizeLimit, fileSize, fileList);

    const isInLimit = fileSize < maxFileSizeLimit;
    if (!isInLimit) {
      errorTotal = errorTotal + 1
      message.error('上传文件不要超过' + maxFileSizeLimit + 'M');
    }
    uploadTotal = fileList.length
    return isInLimit;
  };

  const getUploadApiExtraData = (file: RcFile) => {
    return {
      wuye_uuid: localStorage.getItem('wyUuid'),
      isFace: props.isFace,
    };
  };

  // const resizeFile = (file: RcFile) => {
  //     return new Promise((resolve) => {
  //         Resizer.imageFileResizer(
  //             file,
  //             1028,
  //             1028,
  //             "jpeg",
  //             80,
  //             0,
  //             (uri) => {
  //                 resolve(uri);
  //             },
  //             "file"
  //         );
  //     });
  // }

  // const transformFile = (file: RcFile) => {

  //     return resizeFile(file).then((url: any) => {
  //         console.log('文件大小：：：', file.size, url.size);
  //         return url
  //     }).catch((e: any) => {
  //         console.log('发生异常', e);
  //         return file
  //     });

  //     // return lrz(file, {quality: 0.85}).then((rt: any) => {
  //     //     let soureFile = rt.file;
  //     //     console.log('origin ', rt.origin.exifdata.Orientation)
  //     //     console.log('文件大小：：：', file.size, soureFile.size);
  //     //     return new Promise(function (resolve, reject) {
  //     //         resolve(soureFile);
  //     //     })
  //     // }).catch((e: any) => {
  //     //     console.log('发生异常', e);
  //     //     return new Promise(function (resolve, reject) {
  //     //         resolve(file);
  //     //     })
  //     // });

  //     // return new Promise(function (resolve, reject) {
  //     //     resolve(data);
  //     // })
  //     // return Resizer.imageFileResizer(
  //     //     file,
  //     //     1028,
  //     //     960,
  //     //     "JPEG",
  //     //     100,
  //     //     0,
  //     //     (uri) => {
  //     //         return new Promise((resolve) => {
  //     //             resolve(uri);
  //     //         })
  //     //     },
  //     //     "file"
  //     // );

  // }
  const handlePreview = async (file: any) => {
    console.log('file::::', file);

    setPreviewVisible(true);
    setPreviewImage(file.url);
  };
  const uploaderProps = {
    name: 'file',
    action: Api.apiRootPath + '/api/picture/upload_qiniu_two', // 文件上传提交地址
    beforeUpload: beforeUploadFile,
    onChange: onChangeFile,
    data: getUploadApiExtraData,
    // transformFile: transformFile,
    showUploadList: {
      showRemoveIcon: true,
      showDownloadIcon: false,
      showPreviewIcon: true,
    },
  };
  const uploadBtn = () => {
    if (props.listType === 'picture') {
      return <Button icon={<UploadOutlined/>}>Upload</Button>;
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div>
          <PlusOutlined/>
        </div>
        <div>
          {!props.hiddenImgCorp ?
            <p className="ant-upload-hint">请上传比例是{props.widthRatio}:{props.heightRatio}的图片 </p> :
            <p className="ant-upload-hint">点击或拖拽图片到此处上传</p>
          }
        </div>
      </div>
    );
  };

  const handleDownload = (e) => {
    console.log("uploadDownLoad", e);

  }

  const down = () => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', previewImage, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([this.response]);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = previewImage.substring(previewImage.lastIndexOf("/") + 1);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      }
    };
    xhr.send();
  }
  return (
    <>
      {!props.hiddenImgCorp ? (
        <>
          <ImgCrop quality={1} aspect={props.widthRatio / props.heightRatio} rotate>
            <Upload
              disabled={props.disabled || false}
              {...uploaderProps}
              listType={props.listType || 'picture-card'}
              accept={'image/*'}
              key={dateKey}
              fileList={fileList}
              // onDownload={handleDownload}
              onPreview={handlePreview}
            >
              {props.multiple ? uploadBtn() : fileList.length > 0 ? null : uploadBtn()}
            </Upload>
          </ImgCrop>
        </>
      ) : (
        <Upload
          disabled={props.disabled || false}
          {...uploaderProps}
          listType={props.listType || 'picture-card'}
          accept={'image/*'}
          key={dateKey}
          fileList={fileList}
          multiple={props.multiple}
          // onDownload={handleDownload}
          onPreview={handlePreview}
        >
          {props.multiple ? uploadBtn() : fileList.length > 0 ? null : uploadBtn()}
        </Upload>
      )}
      <Modal
        title={'下载'}
        visible={previewVisible}
        // footer={}
        okText={"下载"}
        onOk={down}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{width: '100%'}} src={previewImage}/>
      </Modal>
    </>
  );
};
export default UploadImgFormItem;
