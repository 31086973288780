import Api from '../data/API';

export async function getList(params: any) {
  return Api.postWithAuth('/admin/api/hotel/list', params);
}
export async function addHotel(params: any) {
  return Api.postWithAuth('/admin/api/hotel/add', params);
}
export async function updateHotel(params: any) {
  return Api.postWithAuth('/admin/api/hotel/update', params);
}
export async function deleteHotel(params: any) {
  return Api.postWithAuth('/admin/api/hotel/delete', params);
}

export async function getRoomList(params: any) {
  return Api.postWithAuth('/admin/api/hotel/room/list', params);
}
export async function addRoomHotel(params: any) {
  return Api.postWithAuth('/admin/api/hotel/room/add', params);
}
export async function updateHotelRoom(params: any) {
  return Api.postWithAuth('/admin/api/hotel/room/update', params);
}
export async function deleteHotelRoom(params: any) {
  return Api.postWithAuth('/admin/api/hotel/room/delete', params);
}
