
import React from 'react';
import MiniApp, {MiniAppMenu, MiniAppRoute} from "../../util/MiniApp";
import HotelList from "./pages/HotelList";
const index: React.FC = () => {
  const rootUrl = '/hotel';
  const menus: MiniAppMenu[] = [
    {
      name: '酒店列表',
      path: 'list',
      authority: 'hotelManager:list',
      component: HotelList,
    },
  ];
  const routes: MiniAppRoute[] = [
  ];
  return <MiniApp miniAppName={'住宿管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
export default index;
