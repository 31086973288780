import React, {useEffect, useRef, useState} from 'react';
import AuthorizedView from '../../../util/AuthorizedView';
import {Button, DatePicker, Input, message, Modal, Select, Space, Spin, Tooltip} from 'antd';
import {
    confirmQyOrder,
    findAllKinds,
    findFee,
    paySynchrWuye,
    refundSynchrWuye,
    synchrWuye,
    wxpayRefundLaunchLiucheng,
} from '../../../services/orderService';
import appConfig from '../../../appConfig';
import API from '../../../data/API';
import {ActionType} from '@ant-design/pro-table';
import OrderDetail from '../../meeting/components/OrderDetail';
import {meetingOrderList, writeOffOrder} from '../../../services/meetingService';
import GSTable from '../../../components/GSTable';
import {getProductList} from '../../../services/Product';
import DAL from '../../../data/DAL';
import * as XLSX from 'xlsx';
import {getWxBusinessList} from '../../../services/Wx';

interface OrderReport {
    startDay?: any;
    endDay?: any;
    fee?: string;
    isOrderReport?: boolean;
    isRefund?: any;
    commodityUuid?: any;
    kind?: any;
}

const OrderList: React.FC<OrderReport> = (props: any) => {
    useEffect(() => {
        console.log("props:::", props)

        if (props.isOrderReport) {
            setFee(props.fee);
            setStartDay(props.startDay);
            setEndDay(props.endDay);
            GetUrlParms('startDay', props.startDay);
            GetUrlParms('endDay', props.endDay);
        }
        if (props.kind != '' && props.kind != null && props.kind != undefined) {
            setKind(props.kind);
        }
        setCommodityUuid(props.commodityUuid || '');
        getAllKinds().then();
        getFees().then();
        getAllProduct().then();
        getWxBusiness().then()
    }, []);
    const [confirmVisible, setConfirmVisible] = useState(false)
    const [productList, setProductList] = useState([]);
    const moment = require('moment');
    let {RangePicker} = DatePicker;
    const actionRef = useRef<ActionType>();
    const [outNoSearch, setOutNoSearch] = useState("")
    const [outTradeNo, setOutTradeNo] = useState("")
    const [order, setOrder] = useState({});
    const [orderUuid, setOrderUuid] = useState("")
    const [commodityUuid, setCommodityUuid] = useState('');
    const [visible, setVisible] = useState(false);
    const [kind, setKind] = useState('all');
    const [kinds, setKinds] = useState([]);
    const [payStatus, setPayStatus] = useState('');
    const [fee, setFee] = useState('');
    const [fees, setFees] = useState([]);
    const [paySynchr, setPaySynchr] = useState('');
    const [startDay, setStartDay] = useState('');
    const [endDay, setEndDay] = useState('');
    const [email, setEmail] = useState('');
    const [url, setUrl] = useState('');
    const [loading, setloading] = useState(false)
    const [selectedRowKeys, setselectedRowKeys] = useState([])
    const [quanXuan, setquanXuan] = useState(false)
    const [dataTotal, setdataTotal] = useState(0)
    const [wxBusinessList, setWxBusinessList] = useState([])
    const [wxBusinessEnum, setWxBusinessEnum] = useState({})
    const [wxBusinessUuid, setWxBusinessUuid] = useState("")
    const columns = [
        {
            title: '序号',
            dataIndex: 'indexNo',
            key: 'indexNo',
        },
        {
            title: '订单编号',
            dataIndex: 'order_no',
            key: 'order_no',
            render: (text: string, record: any) => (
                <a
                    onClick={() => {
                        getOneOrder(record);
                    }}
                >
                    {text}
                </a>
            ),
        },
        {
            title: '订单名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '房间号',
            dataIndex: 'roomNumber',
            key: 'roomNumber',
        },
        {
            title: '订单描述',
            hideInTable: true,
            dataIndex: 'commodityDetail',
            key: 'commodityDetail',
        },
        {
            title: '商户订单号',
            dataIndex: 'out_trade_no',
            key: 'out_trade_no',
        },
        {
            title: '订单类型',
            dataIndex: 'kind',
            key: 'kind',
        },
        {
            title: '费用科目',
            dataIndex: 'feeName',
            key: 'feeName',
        },
        {
            title: '下单用户',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '用户姓名',
            dataIndex: 'realName',
            key: 'realName',
        },
        {
            title: '下单时间',
            dataIndex: 'order_time',
            key: 'order_time',
        },
        {
            title: '订单状态',
            dataIndex: 'is_pay',
            key: 'is_pay',
        },
        {
            title: '总价/元',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: '支付时间',
            dataIndex: 'pay_time',
            key: 'pay_time',
        },
        {
            title: '退款时间',
            dataIndex: 'refund_time',
            key: 'refund_time',
        },
        {
            title: '支付方式',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: '支付商户',
            dataIndex: 'wxBusinessUuid',
            key: 'wxBusinessUuid',
            valueEnum: wxBusinessEnum
        },
        {
            title: '同步物业',
            dataIndex: 'wuyePayResult',
            key: 'wuyePayResult',
            render: (text: string, record: any) => (
                <span>
					{record.total_price === 0 ? (
                        ''
                    ) : record.is_pay === '支付成功' ? (
                        <span>
							{record.wuyePayResult ? (
                                '同步成功'
                            ) : (
                                <AuthorizedView needAuthority={'charge_management:order_manage:synchr_wuye'}>
                                    <a
                                        onClick={() => {
                                            paySynchrWuye1(record);
                                        }}
                                    >
                                        同步失败
                                    </a>
                                </AuthorizedView>
                            )}
						</span>
                    ) : record.is_pay === '退款成功' ? (
                        <span>
							{record.wuyeRefundResult ? (
                                '同步成功'
                            ) : (
                                <AuthorizedView needAuthority={'charge_management:order_manage:synchr_wuye'}>
                                    <a
                                        onClick={() => {
                                            refundSynchrWuye1(record);
                                        }}
                                    >
                                        同步失败
                                    </a>
                                </AuthorizedView>
                            )}
						</span>
                    ) : (
                        ''
                    )}
				</span>
            ),
        },
        {
            title: '操作',
            key: 'action',
            width: 150,
            render: (text: string, record: any) => (
                <>
                    <Space>
                        <>
                            {record.total_price === 0 ? (
                                ''
                            ) : record.is_pay === '支付成功' ? (
                                <AuthorizedView needAuthority={'order:manage:refund'}>
                                    <a
                                        id={record.out_trade_no}
                                        onClick={() => {
                                            getOutTradeNo(record);
                                        }}
                                    >
                                        退款
                                    </a>
                                </AuthorizedView>
                            ) : (
                                ''
                            )}
                        </>
                        {
                          record.is_pay && record.status == 1 && <a onClick={() =>writeOff(record)}>核销</a>
                        }

                        <>
                            {record.is_pay !== '支付成功' && record.payment == 'qiYe' && <a
                                onClick={() => {
                                    openConfirm(record);
                                }}
                            >
                                支付确定
                            </a>}

                        </>
                        <>
                            {(record.is_pay && DAL.dal.isNotNull(record.contractNo) && "true" != record.printStatus) ?
                                <a
                                    target={"_blank"}
                                    href={"/pay_cost_management/print_bill?orderUuid=" + record.orderUuid}
                                >
                                    开票据
                                </a> : "true" == record.printStatus ? <a
                                    target={"_blank"}
                                    href={"/pay_cost_management/print_bill?orderUuid=" + record.orderUuid}
                                >
                                    已开票
                                </a> : ''}

                        </>
                    </Space>
                </>
            ),
        },
    ];

    const getWxBusiness = async () => {
        let rsp = await getWxBusinessList({});
        if (rsp.err === 0) {
            setWxBusinessList(rsp.data)
            let dataEnum = {}
            rsp.data.map(item => {
                dataEnum[item.uuid] = item.name;
            })
            setWxBusinessEnum(dataEnum)
        }

    }
    const openConfirm = (item) => {
        console.log("item:::", item)
        setOutTradeNo("")
        setOrderUuid(item.orderUuid)
        setConfirmVisible(true)
    }
    const writeOff = (item) => {
        Modal.confirm({
            title: '您确认要核销该订单吗?',
            onOk: async () => {
                let params = {
                    uuid: item.orderUuid
                }
                let rsp = await writeOffOrder(params);
                if (rsp.err === 0) {
                    message.success('核销成功');
                    actionRef.current.reload()
                } else {
                    message.error(rsp.msg)
                }

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const isNotNull = (string: any) => {
        if (string !== null && string !== '' && string !== undefined && string != '{}') {
            return true;
        } else {
            return false;
        }
    };
    const getAllProduct = async () => {
        const res = await getProductList({status: 1});
        console.log('res:::', res);
        if (res.err === 0) {
            let data = res.data || [];
            if (isNotNull(res.station)) {
                data.push({uuid: res.station.id, name: '工位'});
            }
            if (isNotNull(res.office)) {
                data.push({uuid: res.office.id, name: '办公室'});
            }
            setProductList(res.data);
        }
    };
    const getFees = async () => {
        const res = await findFee({});
        console.log('科目列表', res);
        setFees(res.data);
    };
    const getAllKinds = async () => {
        const res = await findAllKinds({});
        console.log('getAllKinds:::', res);
        if (res.err === 0) {
            res.data.unshift({kind: '全部订单', name: 'all'});
            setKinds(res.data);
        }
    };
    const getOneOrder = (value: any) => {
        setOrder(value);
        setVisible(true);
    };
    const refundSynchrWuye1 = async (value: any) => {
        const res = await refundSynchrWuye({
            orderUuid: value.orderUuid,
        });
        if (res.err === 0) {
            message.success(res.msg);
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error(res.msg);
        }
    };
    const getOutTradeNo = (ev: any) => {
        Modal.confirm({
            title: '您确认要发起退款流程吗?',
            onOk() {
                launchLiucheng(ev);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const launchLiucheng = async (ev: any) => {
        const res = await wxpayRefundLaunchLiucheng({
            orderUuid: ev.order_uuid,
        });
        if (res.err === 0) {
            window.open(
                appConfig.webCompanyUrl +
                '/page/liucheng/display/user-add-shilie?liucheng_uuid=' +
                res.wxpayRefundLaunchLiucheng +
                '&access_token=' +
                API.getAccessToken() +
                '&user_id=' +
                API.getUserId() +
                '&wyUuid=' +
                localStorage.getItem('wyUuid') +
                '&orderUuid=' +
                ev.order_uuid +
                '&orderName=' +
                ev.name +
                '&feeName=' +
                ev.feeName +
                '&totalPrice=' +
                ev.total_price +
                '&outTradeNo=' +
                ev.out_trade_no
            );
        } else {
            message.error(res.msg);
        }
    };
    const GetUrlParms = (Parm: string, PValue: string) => {
        //Turl: 网址
        //Parm： 参数
        //PValue： 参数值
        let URL, Parms, ParmsArr;
        Parms = '';
        let NewURL = window.location.search;

        if (NewURL.indexOf('?') > -1) {
            let i;
            ParmsArr = NewURL.split('?')[1].split('&');
            if (NewURL.indexOf(Parm) > -1) {
                for (i = 0; i <= ParmsArr.length - 1; i++) {
                    console.log(Parm === ParmsArr[i].split('=')[0]);
                    if (Parm === ParmsArr[i].split('=')[0]) {
                        //原来有参数Parm则改变其值
                        ParmsArr[i] = Parm + '=' + PValue;
                    }
                }
            } else {
                ParmsArr.push(Parm + '=' + PValue);
            }

            console.log('参数集' + ParmsArr);

            for (i = 0; i <= ParmsArr.length - 1; i++) {
                if (i === 0) {
                    Parms = '?' + ParmsArr[i];
                } else {
                    Parms = Parms + '&' + ParmsArr[i];
                }
            }
            NewURL = Parms;
        } else {
            NewURL = '?' + Parm + '=' + PValue;
        }
        setUrl(NewURL);
        window.history.pushState(null, '', NewURL);
    };
    const paySynchrWuye1 = async (value: any) => {
        const res = await paySynchrWuye({
            orderUuid: value.orderUuid,
        });
        if (res.err === 0) {
            message.success(res.msg);
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error(res.msg);
        }
    };

    const dateTime = (date: any, dataToString: any) => {
        setStartDay(dataToString[0]);
        setEndDay(dataToString[1]);
    };

    const confitPay = async () => {
        if (!isNotNull(outTradeNo)) {
            console.log("outTradeNo", outTradeNo)
            message.warning("请输入支付码")
            return
        }
        const res = await confirmQyOrder({orderUuid: orderUuid, outTradeNo: outTradeNo})
        if (res.err == 0) {
            message.success("修改成功");
            setConfirmVisible(false)
            if (actionRef.current) {
                actionRef.current.reload()
            }
        } else if (res.err == -2) {
            message.warning(res.msg);
        } else {
            message.error("修改失败")
        }
    }

    const tableSearchBarRender = () => [
        <Select
            value={kind}
            onChange={(value: any) => {
                setKind(value);
                actionRef.current.reloadAndRest();
            }}
            style={{minWidth: 100}}
        >
            {kinds.map((m: any) => (
                <Select.Option key={m.name} value={m.name}>
                    <Tooltip title={m.kind}>{m.kind}</Tooltip>
                </Select.Option>
            ))}
        </Select>,
        <Select
            defaultValue={payStatus}
            onChange={(e: string) => {
                setPayStatus(e);
                if (actionRef.current) {
                    actionRef.current.reloadAndRest();
                }
            }}
        >
            <Select.Option key={''} value={''}>
                订单状态
            </Select.Option>

            <Select.Option key={'未支付'} value={'0'}>
                未支付
            </Select.Option>
            <Select.Option key={'未支付'} value={'1'}>
                已支付
            </Select.Option>
        </Select>,
        <Select
            defaultValue={fee}
            onChange={(value: any) => {
                setFee(value);
                actionRef.current.reloadAndRest();
            }}
        >
            <Select.Option key="全部科目" value={''}>
                费用科目
            </Select.Option>
            {fees.map((m: any) => (
                <Select.Option key={m.uuid} value={m.uuid}>
                    <Tooltip title={m.feeName}>{m.feeName}</Tooltip>
                </Select.Option>
            ))}
        </Select>,
        <Select
            defaultValue={paySynchr}
            onChange={(value: any) => {
                setPaySynchr(value);
                actionRef.current.reloadAndRest();
            }}
        >
            <Select.Option key="" value={''}>
                同步物业
            </Select.Option>
            <Select.Option key={'true'} value={'true'}>
                <Tooltip title={'同步成功'}>同步成功</Tooltip>
            </Select.Option>
            <Select.Option key={'false'} value={'false'}>
                <Tooltip title={'同步失败'}>同步失败</Tooltip>
            </Select.Option>
        </Select>,
        <Select
            style={{width: 100}}
            defaultValue={wxBusinessUuid}
            onChange={(value: any) => {
                setWxBusinessUuid(value);
                actionRef.current.reloadAndRest();
            }}
        >
            <Select.Option key="" value={''}>
                支付商户
            </Select.Option>
            {
                wxBusinessList.map((item: any) => {
                    return <Select.Option value={item.uuid} key={item.uuid}>{item.name}</Select.Option>
                })
            }
        </Select>,
        <>
            {startDay === null || startDay === undefined || startDay === '' ? (
                <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={['请选择开始时间', '请选择结束时间']}
                    onChange={dateTime}
                    style={{width: 240, paddingLeft: 8}}
                />
            ) : (
                <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={['请选择开始时间', '请选择结束时间']}
                    onChange={dateTime}
                    value={[moment(startDay, 'YYYY-MM-DD'), moment(endDay, 'YYYY-MM-DD')]}
                    style={{width: 240}}
                />
            )}
        </>,
        <Input
            onInput={(ev: any) => {
                setEmail(ev.target.value);
            }}
            placeholder="请输入账号，姓名"
        />,
        <Input
            onInput={(ev: any) => {
                setOutNoSearch(ev.target.value);
            }}
            placeholder="请输入商户订单号"
        />,
        <Select
            value={commodityUuid}
            style={{width: 100}}
            onChange={(value: any) => {
                setCommodityUuid(value);
            }}
            showSearch
            optionFilterProp="children"
        >
            <Select.Option key="产品" value={''}>
                全部产品
            </Select.Option>
            {productList.map((item: any) => {
                return (
                    <Select.Option value={item.uuid} key={item.uuid}>
                        {item.name}
                    </Select.Option>
                );
            })}
        </Select>,
        <Button type="primary" onClick={search}>
            查询
        </Button>,
    ];

    const tableOperationsBarRender = () => [
        <AuthorizedView needAuthority={'charge_management:order_manage:synchr_wuye'}>
            <Button type="primary" onClick={synchrWy}>
                物业同步
            </Button>
        </AuthorizedView>,
    ];
    const synchrWy = async () => {
        const res = await synchrWuye({});
        if (res.err === 0) {
            message.success(res.msg);
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
        } else {
            message.error(res.msg);
        }
    };
    const search = () => {
        if (startDay === '' || startDay === undefined || startDay === null) {
            message.warning('请输入开始时间');

            return;
        }
        if (endDay === '' || endDay === undefined || endDay === null) {
            message.warning('请输入结束时间');
            return;
        }

        if (endDay < startDay) {
            message.warning('结束时间必须大于开始时间');
            return;
        }
        if (actionRef.current) {
            actionRef.current.reloadAndRest();
        }
    };


    const exprotData = async (selectedRows: [any?]) => {
        console.log('selectedRows:::::', selectedRows);
        setloading(true);
        if (quanXuan) {
            const res = await meetingOrderList({
                page: 1,
                size: dataTotal,
                email: email,
                outNoSearch: outNoSearch,
                kind: kind === 'all' ? '' : kind,
                startDay: startDay,
                endDay: endDay,
                fee: fee,
                payStatus: payStatus,
                isOrderReport: props.isOrderReport,
                isRefund: props.isRefund,
                paySynchr: paySynchr,
                refundSynchr: '',
                wxBusinessUuid: wxBusinessUuid,
                commodityUuid: commodityUuid,
            });
            let orderState = '';
            setloading(false);
            if (res.err == 0) {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].is_refund !== '6') {
                        switch (res.data[i].is_refund) {
                            case 1:
                                orderState = '退款处理中';
                                // this.wxRefundQueryRes();
                                break;
                            case 2:
                                orderState = '退款成功';
                                break;
                            case 3:
                                orderState = '退款关闭';
                                break;
                            case 4:
                                orderState = '退款异常';
                                break;
                            case 5:
                                orderState = '申请退款失败';
                                break;
                            case 7:
                                orderState = '已发起退款流程';
                                break;
                            default:
                                if (res.data[i].is_pay) {
                                    orderState = '支付成功';
                                } else {
                                    orderState = '未支付';
                                }
                        }
                    } else {
                        orderState = '订单关闭';
                    }
                    res.data[i].is_pay = orderState;
                }
                setExprotData(res.data);
            } else {
                message.error(res.msg);
            }
        } else {
            setloading(false);
            setExprotData(selectedRows);
        }
    };

    const setExprotData = (selectedRows: [any?]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        columns.map((item: any) => {
            titles.push(item.title);
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children || '');
                        } else {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
                        }
                    } else {
                        data.push("");
                    }
                } else if (item.valueType === "dateTime") {
                    if (selectedRows[i][item.dataIndex]) {
                        data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                    } else {
                        data.push("");
                    }

                } else if (item.valueType === "date") {
                    if (selectedRows[i][item.dataIndex]) {
                        data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD"));
                    } else {
                        data.push("")
                    }
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "记录");

        /* save to file */
        XLSX.writeFile(wb, "记录.xlsx");
    };

    return (
        <>
            <Spin spinning={loading}>

                <GSTable
                    columns={columns}
                    actionRef={actionRef}
                    options={false}
                    rowKey={'orderUuid'}
                    rowSelection={{
                        fixed: true,
                        columnWidth: 100,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys: any, selectedRows: any) => {
                            setquanXuan(false);
                            setselectedRowKeys(selectedRowKeys);
                        },
                        selections: [
                            {
                                key: 'SELECT_ALL',
                                text: '全选全部页面',
                                onSelect: (changableRowKeys: any) => {
                                    setquanXuan(true);
                                    setselectedRowKeys(changableRowKeys);
                                },
                            },
                        ],
                    }}
                    tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
                        return (
                            <Space size={24}>
							<span>
								已选 {quanXuan ? dataTotal : selectedRowKeys.length} 项
								<a
                                    style={{marginLeft: 8}}
                                    onClick={() => {
                                        onCleanSelected();
                                    }}
                                >
									取消选择
								</a>
							</span>
                            </Space>
                        );
                    }}
                    tableAlertOptionRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
                        console.log('tableAlertOptionRender::::', selectedRows);
                        return (
                            <Space size={16}>
                                <a onClick={() => exprotData(selectedRows)}>导出数据</a>
                            </Space>
                        );
                    }}
                    searchBarRender={tableSearchBarRender()}
                    operationsBarRender={tableOperationsBarRender()}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        const res = await meetingOrderList({
                            page: params.current,
                            size: params.pageSize,
                            email: email,
                            outNoSearch: outNoSearch,
                            kind: kind === 'all' ? '' : kind,
                            startDay: startDay,
                            endDay: endDay,
                            fee: fee,
                            payStatus: payStatus,
                            isOrderReport: props.isOrderReport,
                            isRefund: props.isRefund,
                            paySynchr: paySynchr,
                            wxBusinessUuid: wxBusinessUuid,
                            refundSynchr: '',
                            commodityUuid: commodityUuid,
                        });
                        let orderState = '';
                        if (res.err === 0) {


                            if (quanXuan) {
                                let uuids: any = [];
                                res.data.map((item: any) => {
                                    uuids.push(item.orderUuid);
                                });
                                setselectedRowKeys(uuids);
                            }
                            setdataTotal(res.count);
                            for (let i = 0; i < res.data.length; i++) {
                                if (res.data[i].is_refund !== '6') {
                                    switch (res.data[i].is_refund) {
                                        case 1:
                                            orderState = '退款处理中';
                                            // this.wxRefundQueryRes();
                                            break;
                                        case 2:
                                            orderState = '退款成功';
                                            break;
                                        case 3:
                                            orderState = '退款关闭';
                                            break;
                                        case 4:
                                            orderState = '退款异常';
                                            break;
                                        case 5:
                                            orderState = '申请退款失败';
                                            break;
                                        case 7:
                                            orderState = '已发起退款流程';
                                            break;
                                        default:
                                            if (res.data[i].is_pay) {
                                                orderState = '支付成功';
                                            } else {
                                                orderState = '未支付';
                                            }
                                    }
                                } else {
                                    orderState = '订单关闭';
                                }
                                res.data[i].is_pay = orderState;
                            }
                            GetUrlParms('startDay', res.startDay);
                            GetUrlParms('endDay', res.endDay);
                            console.log('getData', res);
                            console.log(res.count);
                            setStartDay(res.startDay);
                            setEndDay(res.endDay);
                        }
                        return {
                            data: res.data,
                            total: res.count,
                            success: true,
                        };
                    }}
                />
                <Modal
                    title="订单详情"
                    visible={visible}
                    onOk={() => {
                        setVisible(false);
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <OrderDetail key={order.toString()} order={order}/>
                </Modal>
                <Modal title="支付确认" visible={confirmVisible} onCancel={() => {
                    setConfirmVisible(false)
                }} onOk={confitPay}>
                    <Input value={outTradeNo} placeholder={
                        "请输入支付码"
                    } onChange={(e) => {
                        setOutTradeNo(e.target.value)
                    }}/>
                </Modal>
            </Spin>
        </>
    );
};
export default OrderList;
