import Api from '../data/API';

export async function meetingFindMeetingList(params: any) {
	return Api.postWithAuth('/api/meeting/meeting_findMeetingList', params);
}

export async function deleteMeeting(params: any) {
	return Api.postWithAuth('/api/meeting/delete_meeting', params);
}
export async function addMeeting(params: any) {
	return Api.postWithAuth('/api/meeting/add_meeting', params);
}
export async function meetingFindByUuid(params: any) {
	return Api.postWithAuth('/api/meeting/meeting_findByUuid', params);
}

export async function deleteMeetingCharge(params: any) {
	return Api.postWithAuth('/api/meeting/delete_meeting_charge', params);
}

export async function meetingCharge(params: any) {
	return Api.postWithAuth('/api/meeting/meeting_charge', params);
}
export async function updateHomeImage(params: any) {
	return Api.postWithAuth('/api/meeting/update_home_image', params);
}
export async function addMeetingImg(params: any) {
	return Api.postWithAuth('/api/meeting/add_meeting_img', params);
}

export async function meetingOrderListOne(params: any) {
	return Api.postWithAuth('/api/meeting/meeting_order_list_one', params);
}

export async function deleteMeetingImg(params: any) {
	return Api.postWithAuth('/api/meeting/delete_meeting_img', params);
}

export async function setFirstImg(params: any) {
	return Api.postWithAuth('/api/meeting/set_first_img', params);
}

export async function updateMeeting(params: any) {
	return Api.postWithAuth('/api/meeting/update_meeting', params);
}

export async function updateOOrderFee(params: any) {
	return Api.postWithAuth('/api/meeting/updateO_order_fee', params);
}

export async function findMeetingName(params: any) {
	return Api.postWithAuth('/api/meeting/find_meeting_name', params);
}

export async function meetingOrderList(params: any) {
	return Api.postWithAuth('/api/meeting/meeting_order_list', params);
}
export async function writeOffOrder(params: any) {
	return Api.postWithAuth('/api/meeting/writeOff', params);
}

export async function orderDetail(params: any) {
	return Api.postWithAuth('/api/meeting/order_detail', params);
}

export async function meetingOrderAdd(params: any) {
	return Api.postWithAuth('/api/meeting_order_add', params);
}

export async function findMeetingPrice(params: any) {
	return Api.postWithAuth('/api/meeting/find_meeting_price', params);
}

export async function queryMeetingTime(params: any) {
	return Api.postWithAuth('/api/meeting/query_meeting_time', params);
}
