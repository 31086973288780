import React, { useState } from 'react';
import ProTable, { ConfigProvider, IntlProvider, zhCNIntl } from '@ant-design/pro-table';
import { Button, message, Space, Spin } from 'antd';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { getHeatlhRecordList } from '../services/TemperatureRecordsService';

export default function GSTable(props: any) {
	const [collapsed, setCollapsed] = useState(true);
	const [total, setTotal] = useState(0);
	const [quanXuan, setQuanXuan] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(false);
	const { pagination = {}, ...rest } = props;
	const finalPagination = !pagination
		? false
		: {
				defaultCurrent: 1,
				defaultPageSize: 10,
				// current: 1,
				// pageSize: 10,
				size: 'small',
				showSizeChanger: true,
				showQuickJumper: true,
				showTotal: (total: string) => <span>共 {total} 条记录</span>,
				...pagination,
		  };

	/**
	 * 渲染表格顶部的工具栏，融合搜索、操作、默认按钮；
	 * 当前样式：筛选/搜索等查询操作左对齐；数据增删改等操作右对齐，默认工具按钮（刷新、设置、全屏）右对齐
	 *
	 * 可以创建其它样式渲染方法供用户选择
	 */
	const toolBarRender = () => {
		// 查询操作
		let a;
		if (props.searchBarRender?.length > 0) {
			a = <Space className="gstable-search-bar">{props.searchBarRender.map((m) => m)}</Space>;
		}

		// 增删改操作
		let b: any = [];
		if (props.operationsBarRender?.length > 0) {
			// b =
			//     <Space className='gstable-operations-bar'>
			//         {
			//             props.operationsBarRender.map(m => {
			//                 b
			//             })
			//         }
			//     </Space>
			{
				props.operationsBarRender.map((m) => {
					b.push(m);
				});
			}
		}
		if (!a && b.length == 0) {
			return null;
		}
		return {
			search: a,
			actions: b,
		};
	};
	const toolBarRender1 = () => {
		return [...props.searchBarRender, ...props.operationsBarRender];
	};
	const tableAlertRender = ({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
		if (props.tableAlertRender) {
			return props.tableAlertRender({ selectedRowKeys, selectedRows, onCleanSelected });
		}
		return (
			<Space size={24}>
				<span>
					已选 {quanXuan ? total : selectedRowKeys.length} 项
					<a
						style={{ marginLeft: 8 }}
						onClick={() => {
							onCleanSelected();
							setQuanXuan(false);
						}}
					>
						取消选择
					</a>
				</span>
			</Space>
		);
	};
	const tableAlertOptionRender = ({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
		if (props.tableAlertOptionRender) {
			return props.tableAlertOptionRender({ selectedRowKeys, selectedRows, onCleanSelected });
		}
		return (
			<Space size={16}>
				<a onClick={() => exprotData(selectedRows)}>导出</a>
			</Space>
		);
	};

	const exprotData = async (selectedRows: [any?]) => {
		if (quanXuan) {
			setLoading(true);
			let data = await props.request({ current: 1, pageSize: total }, false, false);
			setLoading(false);
			if (data.data.length > 0) {
				setExprotData(data.data);
			} else {
				message.error('获取失败');
			}
		} else {
			setExprotData(selectedRows);
		}
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		props.columns.map((item: any) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			props.columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children || '');
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					if (selectedRows[i][item.dataIndex]) {
						data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
					} else {
						data.push('');
					}
				} else if (item.valueType === 'date') {
					if (selectedRows[i][item.dataIndex]) {
						data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD'));
					} else {
						data.push('');
					}
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '记录');

		/* save to file */
		XLSX.writeFile(wb, '记录.xlsx');
	};
	const loadData = async (params: any = {}, sort: any, filter: any) => {
		if (props.request) {
			let data = await props.request(params, sort, filter);
			console.log('data', data);
			if (quanXuan) {
				let uuids: any = [];
				data.data.map((item: any) => {
					uuids.push(item[props.rowKey]);
				});
				setSelectedRowKeys(uuids);
			}
			setTotal(data.total);
			return {
				data: data.data,
				total: data.total,
				success: true,
			};
		}
		return {
			data: [],
			total: 0,
			success: true,
		};
	};
	return (
		<Spin spinning={loading}>
			<ProTable
				rowSelection={
					props.rowSelection || {
						columnWidth:70,
						preserveSelectedRowKeys: true,
						fixed: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setSelectedRowKeys(selectedRowKeys);
							setQuanXuan(false);
						},
						selections: [
							{
								key: 'SELECT_CURRENT_ALL',
								text: '全选当前页面',
								onSelect: (changableRowKeys: any) => {
									setQuanXuan(false);
									setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
								},
							},
							{
								key: 'SELECT_ALL',
								text: '全选全部页面',
								onSelect: (changableRowKeys: any) => {
									setQuanXuan(true);
									setSelectedRowKeys(changableRowKeys);
								},
							},
						],
					}
				}
				scroll={{
					scrollToFirstRowOnChange: true,
					x: 1041
				}}
				tableAlertRender={tableAlertRender}
				tableAlertOptionRender={tableAlertOptionRender}
				search={props.search ? props.search : false}
				options={
					props.options
						? props.options
						: {
								density: false,
						  }
				}
				rowKey={props.rowKey || 'id'}
				toolbar={toolBarRender()}
				size="small"
				// bordered={true}
				pagination={finalPagination}
				{...rest}
				request={loadData}
			/>
		</Spin>
	);
}
