import React, {useRef, useState} from 'react';
import {Button, Col, Form, Image, Input, InputNumber, Modal, Row, Select, Tag, Tooltip} from 'antd';
import {ActionType} from '@ant-design/pro-table';
import {FormInstance} from 'antd/es/form';
import {Link} from 'react-router-dom';
import Api from '../../../data/API';
import {addMeeting, deleteMeeting, meetingFindMeetingList} from '../../../services/meetingService';
import GSTable from '../../../components/GSTable';
import CustomUploadImage from '../../../components/CustomUploadImage';
import {QuestionCircleOutlined} from '@ant-design/icons/lib';
import {connect} from "dva";
import ConnectState from "../../../models/connect";

const confirm = Modal.confirm;
let msg = require('../../../util/SuspensionMsg');
const Meeting: React.FC = (props: any) => {
  const [form] = Form.useForm<FormInstance>();
  const FormItem = Form.Item;
  const {common} = props
  const {deviceList} = common;
  const actionRef = useRef<ActionType>();
  const [addMeetvisible, setAddMeetvisible] = useState(false);
  const [picture, setPicture] = useState('');
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: <>图片</>,
      tip: '该图片在会议室列表页面展示',
      key: 'picture',
      dataIndex: 'picture',
      width: '10%',
      render: (text: string, record: any) => (
        <span>
					<Image src={Api.cdnRootPath + record.picture} width={80} height={80}/>
				</span>
      ),
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <Link to={'/meeting/meeting-detail?uuid=' + record.uuid}>
            <a id={'ts' + record.uuid + '1'}>{text}</a>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: '状态',
      dataIndex: 'state',
      key: 'name',
      width: '6%',
      render: (text: string, record: any) => (
        <Tag color={record.state === 0 ? 'green' : 'red'}>{record.state === 0 ? '正常' : '暂停'}</Tag>
      ),
    },
    {
      title: '收费单位（分钟）',
      dataIndex: 'charge_unit',
      key: 'charge_unit',
      width: '10%',
    },
    {
      title: <>价格（元）</>,
      tip: '单位收费时间段的价格',
      dataIndex: 'price1',
      key: 'price1',
      width: '9%',
      render: (text: string, record: any) => <span>{text}</span>,
    },
    {
      title: '容纳人数',
      dataIndex: 'people_num',
      key: 'people_num',
      width: '7%',
      render: (text: string, record: any) => <span>{text}</span>,
    },
    {
      title: '设备',
      dataIndex: 'equipment',
      key: 'equipment',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <span id={'ts' + record.uuid + '5'}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '服务电话',
      dataIndex: 'linkedman',
      key: 'linkedman',
      width: '10%',
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address',
      width: '18%',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <span id={'ts' + record.uuid + '4'}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: '5%',
      render: (text: string, record: any) => (
        <a
          onClick={() => {
            jinAndHuo(record);
          }}
        >
          {record.state === 0 ? '禁用' : '启用'}
        </a>
      ),
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 16},
    },
  };

  const jinAndHuo = (record: any) => {
    confirm({
      title: '你确定执行该操作吗?',
      onOk: async () => {
        const res = await deleteMeeting({
          uuid: record.uuid,
        });
        if (res.err === 0) {
          msg.suspensionMsg({content: res.msg, type: 'success'});
          if (actionRef.current) {
            actionRef.current.reload();
          }
        } else {
          msg.suspensionMsg({content: res.msg});
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const insertMeeting = () => {
    form.validateFields().then(async (data: any) => {
      if (data.endTime < data.startTime || data.endTime === data.startTime) {
        msg.suspensionMsg({content: '可使用结束时间必须大于开始时间', type: 'danger'});
        return;
      }

      let regP = '^((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\\d{8}$';
      if (!data.linkedman.match(regP)) {
        msg.suspensionMsg({content: '请输入正确的手机号', type: 'danger'});
        return;
      }
      const formData: any = {
        area: data.area,
        picture: picture,
        name: data.name,
        price: data.price,
        people_num: data.peopleNum,
        auth_time: data.authTime,
        start_time: data.startTime,
        end_time: data.endTime,
        address: data.address,
        linkedman: data.linkedman,
        equipment: data.equipment,
        deviceID: data.deviceId,
      };
      setLoading(true)
      const res = await addMeeting(formData);
      setLoading(false);
      if (res.err === 0) {
        if (actionRef.current) {
          actionRef.current.reload();
        }
        msg.suspensionMsg({content: res.msg, type: 'success'});
        setAddMeetvisible(false);
        form.resetFields();
        setPicture('');
      } else {
        msg.suspensionMsg({content: res.msg});
      }

      console.log('data:::', data);
    });
  };
  const tableOperationsBarRender = () => [
    <Button
      type="primary"
      onClick={() => {
        setAddMeetvisible(true);
      }}
    >
      添加会议室
    </Button>,
  ];

  const getMeetFileName = (fileList: any) => {
    console.log('fileList', fileList);
    if (fileList.file.status === 'done') {
      setPicture(fileList.file.response.url);
      console.log(`${fileList.file.name}`);
      console.log('会议', fileList.file.response.url);
    } else if (fileList.file.status === 'error') {
      msg.suspensionMsg({content: `${fileList.file.name} 上传图片失败`, type: 'danger'});
    }
  };

  return (
    <div>
      <GSTable
        columns={columns}
        actionRef={actionRef}
        operationsBarRender={tableOperationsBarRender()}
        request={async (params: any = {}, sort: any, filter: any) => {
          const res = await meetingFindMeetingList({
            page: params.current,
            size: params.pageSize,
            kind: '2',
          });
          return {
            data: res.data,
            total: res.count,
            success: true,
          };
        }}
      />
      <Modal
        title="添加会议室"
        visible={addMeetvisible}
        onOk={insertMeeting}
        confirmLoading={loading}
        onCancel={() => {
          setAddMeetvisible(false);
        }}
        width={990}
      >
        <Form {...formItemLayout} form={form}>
          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={12}>
              <FormItem label={'名称'} name={'name'} rules={[{required: true}]}>
                <Input autoFocus={true} placeholder={'请输入名称'}/>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={'价格'}
                tooltip={'单位收费时间段的价格'}
                name={'price'}
                rules={[{required: true}]}
              >
                <InputNumber min={0} style={{width: '100%'}} placeholder={'元/半小时'}/>
              </FormItem>
            </Col>
          </Row>
          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={24}>
              <FormItem labelCol={{span: 4}} wrapperCol={{span: 20}} label={'地址'} name={'address'}>
                <Input placeholder={'请输入地址'}/>
              </FormItem>
            </Col>
          </Row>

          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={12}>
              <FormItem label={'面积'} name={'area'} rules={[{required: true}]}>
                <InputNumber min={0} style={{width: '100%'}} placeholder={'m²'}/>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={'钥匙授权提前时间'}
                name={'authTime'}
                tooltip={'当会议室关联智能门禁时，预定会议室后发送电子钥匙有效期的提前时间'}
                rules={[{required: true}]}
              >
                <InputNumber style={{width: '100%'}} min={1} max={30} placeholder={'分钟'}/>
              </FormItem>
            </Col>
          </Row>
          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={12}>
              <FormItem
                label={'开始时间'}
                tooltip={'只有在使用时间段内的时间可以预定'}
                name={'startTime'}
                rules={[{required: true}]}
              >
                <InputNumber
                  style={{width: '100%'}}
                  placeholder={'每天使用时间限制-开始'}
                  min={1}
                  max={24}
                  step={1}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={'结束时间'}
                tooltip={'只有在使用时间段内的时间可以预定'}
                name={'endTime'}
                rules={[{required: true}]}
              >
                <InputNumber
                  style={{width: '100%'}}
                  placeholder={'每天使用时间限制-结束'}
                  min={1}
                  max={24}
                  step={1}
                />
              </FormItem>
            </Col>
          </Row>
          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={12}>
              <FormItem label={'设备'} name={'equipment'} rules={[{required: true}]}>
                <Input placeholder={'请输入设备'}/>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={'容纳人数'} name={'peopleNum'} rules={[{required: true}]}>
                <InputNumber style={{width: '100%'}} min={1} step={1}/>
              </FormItem>
            </Col>
          </Row>
          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={12}>
              <FormItem label={'服务电话'} name={'linkedman'} rules={[{required: true}]}>
                <Input style={{width: '100%'}}/>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={'关联门禁'} name={'deviceId'}>
                <Select
                  optionFilterProp="children"
                  showSearch
                  style={{width: '100%'}}>
                  {deviceList.length > 0 ?
                    deviceList.map((item: any) => {
                      return <Select.Option key={item.deviceid} value={item.deviceid}>{item.description}</Select.Option>
                    })
                    : ''}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row className={'margin-top--10'} justify={'start'} style={{alignItems: 'center'}}>
            <Col span={12}>
              <FormItem label={'图片'} tooltip={'该图片在会议室列表页面展示'} name={'picture'}>
                <CustomUploadImage cdn={"public"} multiple={false} listType="picture-card"
                                   onChange={getMeetFileName}/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default connect(({common}: ConnectState) => ({
  common: common,
}))(Meeting);

