import * as React from 'react';
import DAL from '../../../data/DAL';
import Api from '../../../data/API';
import WidgetComponents from '../../../widget/WidgetComponents';
import WangEditor from '../../../components/WangEditor';
import widget from '../../../widget/Widget';
import {
    DatePicker,
    Button,
    Modal,
    Input,
    Dropdown,
    Menu,
    Row,
    Col,
    Radio,
    Checkbox,
    Cascader,
    Upload,
    Select,
} from 'antd';
import {PlusOutlined} from '@ant-design/icons/lib';
import InputTime from '../../../js/InputTime';

require('./addHuodong.css');
let {RangePicker} = DatePicker;
let moment = require('moment');
let kuozhan = InputTime.inputTime;
let msg = require('../../../util/SuspensionMsg');
const confirm = Modal.confirm;
let relType = [
    {name: '姓名', type: 'name'},
    {name: '手机号', type: 'phoneNumber'},
    {
        name: '身份证号',
        type: 'idCard',
    },
    {name: '性别', type: 'sex'},
    {name: '住址', type: 'address'},
    {name: '出生日期', type: 'dateOfBirth'},
    {
        name: '房间号',
        type: 'roomNumber',
    },
    {name: '户口所在地', type: 'registeredResidence'},
    {name: '公司', type: 'company'},
    {
        name: '部门',
        type: 'bumen',
    },
    {name: '职位', type: 'position'},
    /*{name: '电话', type: 'phone'},*/ {name: '网址', type: 'URL'},
    {name: '项目编号', type: 'projectNumber'},
    {name: '用户UUID', type: 'userUuid'},
    {name: '团队类型', type: 'teamType'},
    {name: '产业方向', type: 'industrialDirection'},
];

class AddHuodong extends React.Component {
    state: any;
    wid = new widget(1);
    dal = DAL.dal;
    UE1 = (window as any).UE;
    allData: any = [];
    isUpdata = false;

    constructor(props: any) {
        super(props);
        this.state = {
            isShow: false,
            huodongInitiator: '',
            huodongInitiatorPhone: '',
            userId: '',
            huodongBiaoti: '',
            huodongXiangqing: '',
            tupianUrl: '',
            lbs: '',
            isDelete: '',
            huodongFaqiDate: '',
            huodongJiezhiDate: '',
            huodongJieshuDate: '',
            Customizing: false,
            huodongKaishiDate: '',
            date: [''],
            huodongUuid: '',
            huodongFormUuid: '',
            type: '',
            money: '',
            people: '',
            zhuangTai: '未开始',
            ticketDescribe: '',
            provinces: [],
            cities: [],
            counties: [],
            ticket: [],
            areaName: '',
            id: '',
            areaParentId: '',
            cityArea: '',
            cityArea1: '',
            cityArea2: '',
            isHidde: false,
            isCheck: '',
            moneys: [],
            peoples: [],
            ticketDescribes: [],
            isChecks: [],
            cityAreas: '',
            date_kind: 'end_time',
            selectIsShenhe: {},
            buttomPostion: -1, // 用来标记点击选择button的下标
            selectType: {}, //活动的类型
            ticketData: [{type: '', money: '', number: '', detail: '', isAudit: ''}],
            ziduan: [],
            widget: [],
            substance: [],
            relTypes: relType, //关联选择属性
            widgetType: '',
            widgetName: '',
            descr: '', //描述
            aliasName: '', //别名
            field: '', //扩展字段key
            field1: '', //扩展字段value
            attributeValue: {name: '选择关联属性', type: ''}, //属性值，只有关联组件才会有
            addWidgetState: false,
            select: false,
            nature: true,
            buttonX: 100, //点击添加字段button x 坐标
            buttonY: 100, //点击添加字段button Y 坐标
            dangqian_jiedian_index: 0,
            dangqian_jiedian_name: '',
            dangqian_jiedian_miaoshu: '',
            shifoubixuan: false, //是否是必须选择
            liebiaoxianshi: false, //是否在列表显示
            yonghujujue: false, //用户是否可以拒绝
            morenzhi: '', //默认值
            allow_edit: true, //在关联字段中设置用户是否可以修改里面的数据
            is_hide: false, //是否隐藏
            is_newDate: false, //是否默认当前时间
            deleteJiedianId: 0, //点击删除节点的ID
            deleteZiduan: '0', //点击删除字段，分别是节点顺序和字段顺序
            updataZiduan: -1, //更新字段的位置，-1代表没有更新位置
            selectButtonValue: '', //添加多选按钮获得单选按钮的时候，默认值
            selectButtonNumber: ['默认值', '默认值'], //设置默认单选框获得多选框的个数
            OrderBuy: [{name: '节点：0', uuid: 0}],
            isUpdata: false, //是更新流程还是新建立一个流程
            isHideReviewer: false, //是否隐藏审核人信息
            liuchengdan_type: '', //流程单的类型，默认为空值
            extendedField: ['', ''], //扩展字段
            requestethodm: '',
            choiceState: '',
            choiceValue: '',
            splicingSql: '',
            widgetText: '',
            widgetVisible: false,
            defaultCityArea: [],
            classification: '',
            classificationText: '请选择',
            wEditorValue: '',
        };
        this.getData = this.getData.bind(this);
        this.getHuodongInitiator = this.getHuodongInitiator.bind(this);
        this.getHuodongBiaoti = this.getHuodongBiaoti.bind(this);
        this.getHuodongInitiatorPhone = this.getHuodongInitiatorPhone.bind(this);
        this.getLbs = this.getLbs.bind(this);
        this.addFabu = this.addFabu.bind(this);
        this.getImg = this.getImg.bind(this);
        this.addTicket = this.addTicket.bind(this);
        this.inputDateTime = this.inputDateTime.bind(this);
        this.getZhuangTai = this.getZhuangTai.bind(this);
        this.getHuodongJiezhiDate = this.getHuodongJiezhiDate.bind(this);
        this.getHuodongJieshuDate = this.getHuodongJieshuDate.bind(this);
        this.getHuodongKaishiDate = this.getHuodongKaishiDate.bind(this);
        this.delTicket = this.delTicket.bind(this);
        this.getHtmls = this.getHtmls.bind(this);
        this.zidingTime = this.zidingTime.bind(this);
        this.end = this.end.bind(this);
        this.ProvincesCitiesCounties = this.ProvincesCitiesCounties.bind(this);
        this.dropDownShenhe = this.dropDownShenhe.bind(this);
        this.scelect = this.scelect.bind(this);
        this.getpostion = this.getpostion.bind(this);
        this.selectType = this.selectType.bind(this);
        this.getType = this.getType.bind(this);
        this.inputVlue = this.inputVlue.bind(this);
        this.addWidget = this.addWidget.bind(this);
        this.cancelAddConfirmWidget = this.cancelAddConfirmWidget.bind(this);
        this.cleanWidget = this.cleanWidget.bind(this);
        this.shifoubixuan = this.shifoubixuan.bind(this);
        this.getWidgetName = this.getWidgetName.bind(this);
        this.callback = this.callback.bind(this);
        this.confirmWidget = this.confirmWidget.bind(this);
        this.updataZiduan = this.updataZiduan.bind(this);
        this.deleteZiduan = this.deleteZiduan.bind(this);
        this.querenDeleteZiduan = this.querenDeleteZiduan.bind(this);
        this.shubiaoPsition = this.shubiaoPsition.bind(this);
        this.associationAttributes = this.associationAttributes.bind(this);
        this.morenzhi = this.morenzhi.bind(this);
        this.selectButtonValue = this.selectButtonValue.bind(this);
        this.addSelectItem = this.addSelectItem.bind(this);
        this.is_newDate = this.is_newDate.bind(this);
        this.isUpdataValue = this.isUpdataValue.bind(this);
    }

    componentDidMount() {
        let page = window.location.search;
        let c = page.split('=');
        let uuid = c[1];
        if (uuid !== null && uuid !== undefined && uuid !== '') {
            this.getData();
            this.isUpdata = true;
            this.setState({
                huodongUuid: uuid,
            });
        }
        this.ProvincesCitiesCounties();
    }

    getData() {
        let ser = window.location.search;
        let sp = ser.split('=');
        if (sp[1] === undefined) {
            msg.suspensionMsg({content: 'uuid异常', type: 'danger'});
            return;
        }
        Api.postWithAuth('/api/huodong/find_huodong_etails', {
            uuid: sp[1],
        }).then((res) => {
            console.log('获得详细信息', res.data);
            let da = res.data;
            this.state.defaultCityArea.push(da.cityArea);
            this.state.defaultCityArea.push(da.cityArea1);
            this.state.defaultCityArea.push(da.cityArea2);
            this.setState({
                ticketUuids: da.ticketUuids,
                huodongKaishiDate: da.huodongKaishiDate,
                huodongJieshuDate: da.huodongJieshuDate,
                Customizing:
                    da.huodongJieshuDate === '' || da.huodongJieshuDate === null || da.huodongJieshuDate === undefined
                        ? false
                        : true,
                huodongBiaoti: da.huodongBiaoti,
                cityArea: da.cityArea,
                cityArea1: da.cityArea1,
                cityArea2: da.cityArea2,
                ziduan: da.huodingJiegou,
                huodongInitiator: da.huodongInitiator,
                huodongInitiatorPhone: da.huodongInitiatorPhone,
                huodongJiezhiDate: da.huodongJiezhiDate,
                tupianUrl: da.tupianUrl,
                date: da.piaozhong,
                ticketData: da.piaozhong,
                zhuangTai: da.zhuangTai,
                // defaultCityArea: this.state.defaultCityArea,
                classification: da.classification,
                classificationText: da.classification,
                lbs: da.lbs,
                huodongXiangqing: da.huodongXiangqing,
                wEditorValue: da.huodongXiangqing,
            });

            console.log(
                '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
                da.huodingJiegou,
                this.state.defaultCityArea
            );
            let piaozhong = da.piaozhong;
            for (let j = 0; j < piaozhong.length; j++) {
                let a = piaozhong[j];
                (this.state as any).selectType[j] = a.type;
                (this.state as any).moneys[j] = a.money;
                (this.state as any).peoples[j] = a.people;
                (this.state as any).ticketDescribes[j] = a.ticketDescribe;
                (this.state as any).isChecks[j] = a.isAudit;
                (this.state as any).selectIsShenhe[j] = a.isAudit;
            }
            this.setState({
                selectType: (this.state as any).selectType,
                isApply: res.isApply,
            });
            //selectType
            /*if (da.huodongXiangqing !== null && da.huodongXiangqing !== undefined) {
                let that = this;
                setTimeout(
                    function () {
                        that.UE1.getEditor('myDivContainer').setContent(da.huodongXiangqing);
                    },
                    500);
            }*/
        });
    }

    addFabu(start: string) {
        let sta = this.state as any;
        let zhangTai = '';
        if (this.state.huodongUuid !== undefined && this.state.huodongUuid !== null && this.state.huodongUuid !== '') {
            this.setState({
                zhuangTai: '未开始',
            });
            zhangTai = '未开始';
        } else {
            this.setState({
                zhuangTai: '已关闭',
            });
            zhangTai = '已关闭';
        }

        // this.setState({
        //     zhuangTai: start
        // });
        console.log(sta.huodongJieshuDate + '---' + sta.huodongJiezhiDate + '---' + sta.huodongKaishiDate);
        let regP = '(^(0[0-9]{2,3}\\-)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?$)|(^0?[1][3456789][0-9]{9}$)';
        if (!sta.huodongInitiatorPhone.match(regP)) {
            msg.suspensionMsg({content: '手机号格式不正确', type: 'danger'});
            return;
        }

        if (
            sta.huodongJieshuDate === null &&
            sta.huodongJieshuDate === '' &&
            sta.huodongJieshuDate <= sta.huodongKaishiDate
        ) {
            msg.suspensionMsg({content: '结束日期不能为空且大于开始时间', type: 'danger'});
            return;
        }
        if (sta.huodongJiezhiDate > sta.huodongJieshuDate) {
            msg.suspensionMsg({content: '截止时间不能大于活动结束时间', type: 'danger'});
            return;
        }

        if (sta.huodongJiezhiDate === null && sta.huodongJiezhiDate === '' && sta.huodongJiezhiDate === undefined) {
            msg.suspensionMsg({content: '截止时间不能为空', type: 'danger'});
            return;
        }
        if (sta.huodongBiaoti === null) {
            msg.suspensionMsg({content: '标题不能为空', type: 'danger'});
            return;
        }
        if (sta.classification === null || sta.classification === '' || sta.classification === undefined) {
            msg.suspensionMsg({content: '请选择分类', type: 'danger'});
            return;
        }
        if (sta.huodongInitiator === null) {
            msg.suspensionMsg({content: '发起人不能为空', type: 'danger'});

            return;
        }
        if (sta.cityArea === null || sta.cityArea1 === null || sta.cityArea2 === null) {
            msg.suspensionMsg({content: '地址不能为空', type: 'danger'});
            return;
        }
        console.log('(this.state as any).zhuangTai', zhangTai);
        console.log('(this.state as any).huodongUuid', (this.state as any).huodongUuid);
        Api.postWithAuth('/api/huodong/add_huodong', {
            huodongUuid: (this.state as any).huodongUuid,
            huodongInitiator: (this.state as any).huodongInitiator,
            huodongXiangqing: this.state.wEditorValue,
            huodongInitiatorPhone: (this.state as any).huodongInitiatorPhone,
            huodongBiaoti: (this.state as any).huodongBiaoti,
            lbs: (this.state as any).lbs,
            huodongKaishiDate: (this.state as any).huodongKaishiDate,
            huodongJieshuDate: (this.state as any).huodongJieshuDate,
            huodongJiezhiDate: (this.state as any).huodongJiezhiDate,
            tupianUrl: (this.state as any).tupianUrl,
            ticketData: JSON.stringify((this.state as any).ticketData),
            cityArea: (this.state as any).cityArea,
            cityArea1: (this.state as any).cityArea1,
            cityArea2: (this.state as any).cityArea2,
            zhuangTai: zhangTai,
            date_kind: (this.state as any).date_kind,
            classification: this.state.classificationText,
            data: JSON.stringify((this.state as any).ziduan),
        }).then((res) => {
            if (res.err === 0) {
                msg.suspensionMsg({content: '成功', type: 'success'});
                window.location.href = '/huodong/find_huodong_list';
            } else {
                msg.suspensionMsg({content: res.msg});
            }
        });
    }

    getHtmls(e: any) {
        this.setState({
            huodongXiangqing: e,
        });
    }

    getImg(ress: any) {
        this.setState({
            tupianUrl: ress,
        });
    }

    ProvincesCitiesCounties() {
        Api.postWithAuth('/api/huodong/find_city_area_list', {}).then((res) => {
            console.log('1111111111', res.data);
            if (res.err === 0) {
                console.log('三级联动》》》》》》', res.data);
                this.setState({
                    provinces: res.data,
                });
            }
        });
    }

    addTicket() {
        (this.state as any).ticketData.push({type: '', money: '', number: '', detail: '', isAudit: '', uuid: ''});
        this.setState({
            ticketData: (this.state as any).ticketData,
        });
        console.log('data', ...(this.state as any).ticketData);
    }

    delTicket(index: any) {

        if ((this.state as any).ticketData.length === 1) {
            msg.suspensionMsg({content: '票种必须有一个', type: 'danger'});
            return;
        }
        console.log(this.state.ticketData[index])
        let ticketUuid = this.state.ticketData[index].uuid;
        if (ticketUuid && this.state.ticketUuids && this.state.ticketUuids.includes(ticketUuid)) {
            msg.suspensionMsg({content: '该票种已有人报名，无法删除', type: 'danger'});

            return;
        }
        console.log('获得ID', index);
        (this.state as any).ticketData.splice(index, 1);

        this.setState({
            ticketData: (this.state as any).ticketData,
        });
        console.log('date', ...(this.state as any).ticketData);
    }

    getHuodongInitiator(ev: any) {
        this.setState({
            huodongInitiator: ev.target.value,
        });
    }

    getHuodongJiezhiDate(date: any, dateToString: any) {
        console.log('截至' + dateToString);
        this.setState({
            huodongJiezhiDate: dateToString,
        });
    }

    getHuodongKaishiDate(date: any, dateToString: any) {
        console.log('开始' + dateToString);
        this.setState({
            huodongKaishiDate: dateToString,
        });
    }

    getHuodongJieshuDate(date: any, dateToString: any) {
        console.log('结束' + dateToString);
        this.setState({
            huodongJieshuDate: dateToString,
        });
    }

    getLbs(ev: any) {
        this.setState({
            lbs: ev.target.value,
        });
    }

    getHuodongBiaoti(ev: any) {
        this.setState({
            huodongBiaoti: ev.target.value,
        });
    }

    getHuodongInitiatorPhone(ev: any) {
        this.setState({
            huodongInitiatorPhone: ev.target.value,
        });
    }

    getZhuangTai(ev: any) {
        this.setState({
            zhuangTai: ev.target.value,
        });
    }

    zidingTime() {
        this.setState({
            isHidde: false,
            date_kind: 'user-defined',
            Customizing: true,
        });
    }

    end() {
        this.setState({
            isHidde: true,
            Customizing: false,
            huodongJiezhiDate: (this.state as any).huodongJieshuDate,
            date_kind: 'end_time',
        });
        console.log(1, (this.state as any).huodongJieshuDate);
    }

    inputVlue(uuid: any, distinction: any, ref: any) {
        let values = '';
        switch (distinction) {
            case 'money':
                (this.state as any).ticketData[uuid].money = ref.target.value;
                break;
            case 'number':
                if (ref.target.value <= 0) {
                    msg.suspensionMsg({content: '必须大于零！', type: 'danger'});
                    return;
                }
                (this.state as any).ticketData[uuid].people = ref.target.value;
                break;
            case 'detail':
                (this.state as any).ticketData[uuid].detail = ref.target.value;
                break;
            case "name":
                (this.state as any).ticketData[uuid].name = ref.target.value;
                break;
            default:
                values = '';
                break;
        }
        this.setState({
            ticketData: (this.state as any).ticketData,
        });
        console.log(...(this.state as any).ticketData);
    }

    inputDateTime(width: any, height: number, uuid: any, type: string, distinction: any, remark: any) {
        let values = '';
        let isDisabled = false;
        switch (distinction) {
            case 'money':
                values = (this.state as any).ticketData[uuid].money;
                if ((this.state as any).ticketData[uuid].type === '免费') {
                    isDisabled = true;
                }
                break;
            case 'number':
                values = (this.state as any).ticketData[uuid].people;
                break;
            case 'detail':
                values = (this.state as any).ticketData[uuid].detail;
                break;
            case "name":
                values = (this.state as any).ticketData[uuid].name;
                break;
            default:
                values = '';
                break;
        }
        return (
            <div>
                <Input
                    placeholder={remark}
                    type={type}
                    disabled={isDisabled}
                    value={values}
                    onChange={this.inputVlue.bind(this, uuid, distinction)}
                    className={'add-company-count-div2 ' + uuid + '  form-control'}
                    name="ticket"
                />
            </div>
        );
    }

    /**
     * 选择票的类型
     */
    selectType(width: any, height: number, uuid: any, data: any) {
        // const moneyMenu = (
        //     <Menu onClick={this.getType.bind(this, uuid)}>
        //         <Menu.Item>免费</Menu.Item>
        //         <Menu.Item>收费</Menu.Item>
        //     </Menu>
        // );
        return (
            <div>
                <Input
                    type="text"
                    className={'add-company-count-div2 ' + uuid}
                    hidden={true}
                    value={(this.state as any).selectType[uuid]}
                    name="ticket"
                    id={uuid}
                />
                <div>
                    {/*<Dropdown overlay={moneyMenu}>*/}
                    {/*    <Button>*/}
                    {/*        /!*{(this.state as any).ticketData[uuid].type === '' ? '请选择' : (this.state as any).ticketData[uuid].type} <Icon type="down"/>*!/*/}
                    {/*        {(this.state as any).ticketData[uuid].type === '' ? '请选择' : (this.state as any).ticketData[uuid].type} <DownloadOutlined />*/}
                    {/*    </Button>*/}
                    {/*</Dropdown>*/}
                    <Select
                        key={(this.state as any).ticketData[uuid].type}
                        defaultValue={
                            (this.state as any).ticketData[uuid].type === ''
                                ? undefined
                                : (this.state as any).ticketData[uuid].type
                        }
                        placeholder={'类型'}
                        onChange={(e: string) => this.getType(uuid, e)}
                    >
                        <Select.Option value={'免费'}>免费</Select.Option>
                        <Select.Option value={'收费'}>收费</Select.Option>
                    </Select>
                </div>
            </div>
        );
    }

    /**
     * 选择好的类型
     */
    getType(uuid: any, ev: any) {
        let bool = false;
        this.setState({
            buttomPostion: uuid,
        });
        console.log(uuid, ev);
        if (ev === '免费') {
            if ((this.state as any).ticketData.length > 1) {
                if ((this.state as any).ticketData[uuid].type !== '免费') {
                    (this.state as any).ticketData.map((item: any) => {
                        if (item.type === '免费') {
                            msg.suspensionMsg({content: '免费的只能添加一个', type: 'danger'});
                            bool = true;
                            return;
                        }
                    });
                    (this.state as any).ticketData[uuid].money = 0;
                }
            }
            (this.state as any).ticketData[uuid].money = 0;
        } else {
            if ((this.state as any).ticketData[uuid].money === 0) {
                (this.state as any).ticketData[uuid].money = '';
            }
        }
        if (bool) {
            return;
        }
        (this.state as any).ticketData[uuid].type = ev;
        this.setState({
            ticketData: (this.state as any).ticketData,
        });

        console.log(...(this.state as any).ticketData);
    }

    /**
     * 获得点击buttom 的位置
     *
     */
    getpostion(res: any) {
        this.setState({
            buttomPostion: res.target.id,
        });
    }

    /**
     * 选择审核类型
     */
    scelect(uuid: any, ev: any) {
        this.setState({
            buttomPostion: uuid,
        });
        (this.state as any).ticketData[uuid].isAudit = ev;
        this.setState({
            ticketData: (this.state as any).ticketData,
        });
        console.log(...(this.state as any).ticketData);
    }

    dropDownShenhe(width: any, height: number, uuid: any) {
        return (
            <div>
                <Input
                    type="text"
                    className={'add-company-count-div3 ' + uuid}
                    hidden={true}
                    value={(this.state as any).selectIsShenhe[(this.state as any).buttomPostion]}
                    name="ticket"
                    id={uuid}
                />

                <div className="btn-group">
                    <Select
                        key={(this.state as any).ticketData[uuid].isAudit}
                        placeholder={'是否审核报名'}
                        defaultValue={
                            (this.state as any).ticketData[uuid].isAudit === ''
                                ? undefined
                                : (this.state as any).ticketData[uuid].isAudit
                        }
                        onChange={(e: string) => {
                            this.scelect(uuid, e);
                        }}
                    >
                        <Select.Option value={'是'}>是</Select.Option>
                        <Select.Option value={'否'}>否</Select.Option>
                    </Select>
                </div>
            </div>
        );
    }

    /**
     * 点击确认添加组件
     */
    confirmWidget() {
        this.setState({
            widgetVisible: false,
        });
        // (jQuery as any)('#myModal').modal('hide');
        kuozhan.getData('kuozhan', this.callback);
    }

    callback(data: any) {
        console.log('扩展字段', data.kuozhan + '');
        //获得state对象
        let st = this.state as any;
        //descr
        // let ue = (window as any).UE;
        // st.descr = ue.getEditor('Mycontainer').getContent();
        //获得组件类型
        let widgetType = (this.state as any).widgetType;
        //判断该组件是否是关联组件
        if (widgetType === 'rel') {
            //是关联组件判断是否已经关联字段
            let attributeValue = (this.state as any).attributeValue;
            if (attributeValue.type === '') {
                msg.suspensionMsg({content: '请选择关联属性', type: 'danger'});
                return;
            }
        }
        //把信息保存到数组,还有其他字段后面在更改
        //必须放在这个每一次需要重新创建，不然会被覆盖
        let zd = {
            ziduan_name: '未命名',
            ziduan_type: 'text',
            ziduan_order: -1,
            is_required: false,
            select_value: '',
            select_limit: 0,
            guanlian_ziduan: '',
            ziduan_miaoshu: '',
            morenzhi: '',
            allow_edit: true,
            is_hide: false,
            is_newDate: false,
        };
        //赋值
        zd.ziduan_name = st.widgetName;
        zd.ziduan_miaoshu = st.descr;
        zd.ziduan_type = widgetType;
        //获得节点字段对象行
        //获得节点的字段
        let ziduan = (this.state as any).ziduan;
        //获得字段的个数， 当order为-1代表新添加一个
        if (zd.ziduan_order === -1) {
            zd.ziduan_order = ziduan.length;
        }
        zd.is_required = st.shifoubixuan;
        //判断是否是多选或者单选，获得默认值不一样
        if (st.widgetType === 'radio' || st.widgetType === 'checkbox') {
            zd.morenzhi = st.selectButtonNumber.toString();
        } else {
            zd.morenzhi = st.morenzhi;
        }
        zd.allow_edit = st.allow_edit;
        zd.is_hide = st.is_hide;
        zd.is_newDate = st.is_newDate;
        zd.guanlian_ziduan = (this.state as any).attributeValue.type;
        /**
         * 判断字段位置是否为-1如果是-1代表添加一个字段，如果不是服役代表更新指定位置的字段
         *
         */
        if (st.updataZiduan === -1) {
            ziduan.push(zd);
        } else {
            ziduan[st.updataZiduan] = zd;
            st.updataZiduan = -1;
        }
        this.setState({
            select: (this.state as any).select,
        });
        this.cleanWidget();
    }

    /**
     * 点击取消添加组件
     */
    cancelAddConfirmWidget() {
        this.setState({
            widgetVisible: false,
        });
        // (jQuery as any)('#myModal').modal('hide');
        this.cleanWidget();
    }

    /**
     * 取消添加组件/添加组件
     * @returns {any}
     */
    cleanWidget() {
        this.setState({
            addWidgetState: false,
            select: false,
            nature: true,
            widgetType: '', //取出的各种值也初始化
            widgetName: '',
            descr: '',
            attributeValue: {name: '选择关联属性', type: ''},
            dangqian_jiedian_name: '',
            dangqian_jiedian_miaoshu: '',
            shifoubixuan: false,
            morenzhi: '',
            allow_edit: true,
            is_hide: false,
            is_newDate: false,
            selectButtonNumber: ['默认值', '默认值'],
            extendedField: ['', ''], //扩展字段
        });
        // (window as any).UE.getEditor('Mycontainer').setContent('', false);
    }

    /**
     * 获得删除字段的标记
     * @returns {any}
     */
    deleteZiduan(res: any) {
        let that = this;
        console.log('获得要删除字段的ID', res.target.id);
        this.setState({
            deleteZiduan: res.target.id,
        });
        confirm({
            title: '您确认要删除该字段吗？',
            onOk() {
                that.querenDeleteZiduan();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    /**
     * 点击确认删除字段
     * @returns {any}
     */
    querenDeleteZiduan() {
        let st = this.state as any;
        let ziduanId = st.deleteZiduan;
        let pt = st.ziduan;
        pt.splice(ziduanId, 1);
        pt.map((item: any, index: any) => (item.order = index));
        this.setState({
            ziduan: pt,
        });
    }

    /**
     * 点击更新组件
     * @returns {any}
     */
    updataZiduan = (res: any) => {
        console.log('点击更新', res);
        let st = this.state as any;
        let split = res;
        //
        let pt = st.ziduan;
        let ziduanInfo = pt[split];
        let moren = [];
        console.log('ziduanInfo', ziduanInfo.morenzhi, pt, split, pt[split]);
        let mo = ziduanInfo.morenzhi.split(',');
        for (let i = 0; i < mo.length; i++) {
            moren.push(mo[i]);
        }
        this.setState({
            select: true,
            addWidgetState: true,
            updataZiduan: split, //保存更新字段的位置
            widgetName: ziduanInfo.ziduan_name, //获得原有的名字
            descr: ziduanInfo.ziduan_miaoshu, //原有描述值
            morenzhi: ziduanInfo.morenzhi, //原有描述值
            shifoubixuan: ziduanInfo.is_required, //原有必添值
            selectButtonNumber: moren,
        });
        // (window as any).UE.getEditor('Mycontainer').setContent(ziduanInfo.ziduan_miaoshu, false);
    };

    /**
     * 点击添加控件
     */
    addWidget(res: any) {
        console.log('点击添加组件,获得的节点ID' + res.target.id);
        this.setState({
            select: true,
            updataZiduan: -1,
            addWidgetState: true,
        });
    }

    /**
     * 点击选择好的控件
     */
    selectButton(res: any) {
        this.setState({
            widgetType: res.type,
            select: true,
            nature: false,
            widgetText: res.name,
            widgetVisible: true,
        });
    }

    /**
     * 获得组件的名字
     */
    getWidgetName(res: any) {
        console.log('组件的名称', res.target.value);
        this.setState({
            widgetName: res.target.value,
        });
    }

    /**
     * 获得描述的
     */
    describe(res: any) {
        console.log('描述的信息', res.target.value);
        this.setState({
            descr: res.target.value,
        });
    }

    /**
     * 获得得单选或者多选按钮的值
     * @returns {any}
     */
    selectButtonValue(res: any) {
        let st = this.state as any;
        st.selectButtonNumber[res.target.id] = res.target.value;
        this.setState({
            selectButtonNumber: st.selectButtonNumber,
        });
        console.log('获得值的ID', st.selectButtonNumber.toString());
    }

    /**
     * 删除单选或者多选button
     * @returns {any}
     */
    deleteSelectButton = (res: any) => {
        let st = this.state as any;
        console.log('长度', st.selectButtonNumber);
        if (st.selectButtonNumber.length <= 2) {
            msg.suspensionMsg({content: '最少保留两个选项', type: 'danger'});
            return;
        }
        st.selectButtonNumber.splice(res.target.id, 1);
        this.setState({
            selectButtonNumber: st.selectButtonNumber,
        });
    };

    /**
     * 添加单选或者多框的个数
     * @returns {any}
     */
    addSelectItem() {
        let st = this.state as any;
        st.selectButtonNumber.push('默认值');
        this.setState({
            selectButtonNumber: st.selectButtonNumber,
        });
    }

    /**
     * 获得默认值
     * @param res
     */
    morenzhi(res: any) {
        console.log('获得默认值', res.target.value);
        this.setState({
            morenzhi: res.target.value,
        });
    }

    /**
     * 点击必选按钮
     * @param res
     */
    shifoubixuan(res: any) {
        console.log('必选按钮', res.target.checked);
        this.setState({
            shifoubixuan: res.target.checked ? true : false,
        });
    }

    /**
     * 关联属性值的选择
     */
    associationAttributes(res: any) {
        console.log('获得的关联属性值', res.target.id);
        let split = res.target.id.split(',');
        let vaule = {name: split[0], type: split[1]};
        this.setState({
            attributeValue: vaule,
        });
    }

    /**
     * 获得关联字段是否可以修改
     */
    isUpdataValue(res: any) {
        console.log('获得是否允许更改', res.target.value);
        this.setState({
            allow_edit: res.target.checked ? true : false,
        });
    }

    /**
     * 关联字段是否可以隐藏
     * @param res
     */
    isHide(res: any) {
        this.setState({
            is_hide: res.target.value === 'true' ? false : true,
        });
    }

    is_newDate(res: any) {
        this.setState({
            is_newDate: res.target.checked ? true : false,
        });
    }

    /**
     * 获得鼠标点击的位置
     * @returns {any}
     */
    shubiaoPsition(res: any) {
        console.log('鼠标点击事件', res.pageY);
        console.log('滚动距离', res.scrollX);
        let x = res.pageX + res.clientX;
        let y = res.pageY;
        if ((this.state as any).addWidgetState) {
            return;
        }
        this.setState({
            buttonX: x,
            buttonY: y,
        });
    }

    onOk = (value: any) => {
        console.log('onOk: ', value);
    };

    getKaishiDate = (date: any, dateToString: any) => {
        console.log('开始' + dateToString);
        this.setState({
            huodongKaishiDate: dateToString[0],
            huodongJieshuDate: dateToString[1],
        });
    };

    CascaderOnChange = (value: any) => {
        console.log('三级联动', value);
        this.setState({
            cityArea: value[0],
            cityArea1: value[1],
            cityArea2: value[2],
        });
    };

    getFileName = (fileList: any) => {
        console.log('fileList', fileList);
        if (fileList.file.status === 'done') {
            this.setState({
                tupianUrl: fileList.file.response.url,
            });
        } else if (fileList.file.status === 'error') {
            msg.suspensionMsg({content: `${fileList.file.name} 上传图片失败`, type: 'error'});
        }
    };

    handleMenuClick = (e: any) => {
        this.setState({
            classification: e,
            classificationText: e,
        });
    };

    wEditorValue = (value: any) => {
        console.log('WE', value);
        this.setState({
            wEditorValue: value,
        });
    };

    render() {
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="科创">科创</Menu.Item>
                <Menu.Item key="生活">生活</Menu.Item>
            </Menu>
        );
        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div style={{marginTop: 8}}>上传</div>
            </div>
        );
        return (
            <div>
                <Row justify="start">
                    <Col>
                        <span className="addTitle">{this.isUpdata ? '编辑活动' : '创建活动'}</span>
                    </Col>
                </Row>
                <div onMouseDown={this.shubiaoPsition} style={{backgroundColor: '#fff'}}>
                    <Row justify="start">
                        <Col span={18} style={{textAlign: 'right'}}>
                            {/*<Button onClick={this.addFabu.bind(this, '未开始')} type={'primary'}>发布</Button>*/}
                        </Col>
                        <Col span={6} style={{textAlign: 'right'}}>
                            <Button
                                type={'primary'}
                                style={{marginTop: 15, marginRight: 10}}
                                onClick={this.addFabu.bind(this, '已关闭')}
                            >
                                保存
                            </Button>
                        </Col>
                    </Row>
                    <div style={{width: '88%'}}>
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>标题：
                            </Col>
                            <Col>
                                <Input
                                    placeholder={'请输入'}
                                    value={(this.state as any).huodongBiaoti}
                                    onInput={this.getHuodongBiaoti}
                                />
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>分类：
                            </Col>
                            <Col>
                                {/*<Dropdown overlay={menu}>*/}
                                {/*    <Button>*/}
                                {/*        /!*{this.state.classificationText} <Icon type="down" />*!/*/}
                                {/*        {this.state.classificationText} <DownloadOutlined />*/}
                                {/*    </Button>*/}
                                {/*</Dropdown>*/}
                                <Select
                                    key={this.state.classificationText}
                                    defaultValue={this.state.classificationText}
                                    onChange={(e: string) => {
                                        this.handleMenuClick(e);
                                    }}
                                >
                                    <Select.Option key="科创" value={'科创'}>
                                        科创
                                    </Select.Option>
                                    <Select.Option key="生活" value={'生活'}>
                                        生活
                                    </Select.Option>
                                    <Select.Option key="数智+" value={'数智+'}>
                                        数智+
                                    </Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>活动起止时间：
                            </Col>
                            <Col>
                                {(this.state as any).huodongKaishiDate === '' ||
                                (this.state as any).huodongKaishiDate === null ||
                                (this.state as any).huodongKaishiDate === undefined ? (
                                    <RangePicker
                                        showTime={true}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder={['请选择开始时间', '请选择结束时间']}
                                        onChange={this.getKaishiDate}
                                        onOk={this.onOk}
                                    />
                                ) : (
                                    <RangePicker
                                        showTime={true}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder={['请选择开始时间', '请选择结束时间']}
                                        onChange={this.getKaishiDate}
                                        onOk={this.onOk}
                                        value={[
                                            moment(this.state.huodongKaishiDate, 'YYYY-MM-DD HH:mm'),
                                            moment(this.state.huodongJieshuDate, 'YYYY-MM-DD HH:mm'),
                                        ]}
                                    />
                                )}
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>地址：
                            </Col>
                            <Col span={10}>
                                {this.isUpdata ? (
                                    <Cascader
                                        key={this.state.defaultCityArea}
                                        style={{width: '100%'}}
                                        defaultValue={this.state.defaultCityArea}
                                        options={this.state.provinces}
                                        onChange={this.CascaderOnChange}
                                    />
                                ) : (
                                    <Cascader
                                        style={{width: '100%'}}
                                        placeholder={'请选择'}
                                        options={this.state.provinces}
                                        onChange={this.CascaderOnChange}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row justify="start">
                            <Col span={3}/>
                            <Col span={10} style={{textAlign: 'right'}}>
                                <Input
                                    value={(this.state as any).lbs}
                                    placeholder="详细位置,选填"
                                    name="lbs"
                                    onInput={this.getLbs}
                                />
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        {/*       {*/}
                        {/*           (this.state as any).tupianUrl === '' ?*/}
                        {/*               ''*/}
                        {/*               :*/}
                        {/*               <Row  justify="start">*/}
                        {/*                   <Col span={3}/>*/}
                        {/*                   <Col>*/}
                        {/*               <img style={{marginLeft: 10}} className="homePage img-size" src={Api.cdnRootPath + (this.state as any).tupianUrl} defaultValue={(this.state as any).tupianUrl}/>*/}
                        {/*                   </Col>*/}
                        {/*               </Row>*/}
                        {/*       }*/}
                        {/*<br/>*/}
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>上传封面：
                            </Col>
                            <Col span={10}>
                                <Upload
                                    name="image"
                                    key={(this.state as any).uuid}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    data={{wuye_uuid: localStorage.getItem('wyUuid')}}
                                    action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
                                    onChange={this.getFileName}
                                >
                                    {(this.state as any).tupianUrl !== null &&
                                    (this.state as any).tupianUrl !== undefined &&
                                    (this.state as any).tupianUrl !== '' ? (
                                        <img
                                            alt="avatar"
                                            style={{width: '100%'}}
                                            src={Api.cdnRootPath + (this.state as any).tupianUrl}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                                {/*<Upload*/}
                                {/*    key={(this.state as any).uuid}*/}
                                {/*    name="file"*/}
                                {/*    listType={'picture'}*/}
                                {/*    data={{wuye_uuid: localStorage.getItem('wyUuid')}}*/}
                                {/*    action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}*/}
                                {/*    onChange={this.getFileName}*/}
                                {/*    accept={'.jpg,.png'}*/}
                                {/*>*/}
                                {/*    <Button>*/}
                                {/*        <UploadOutlined /> 上传封面*/}
                                {/*        /!*<Icon type="upload"/> 上传封面*!/*/}
                                {/*    </Button>*/}
                                {/*</Upload>*/}
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>主办方：
                            </Col>
                            <Col>
                                <Input
                                    placeholder={'请输入'}
                                    value={(this.state as any).huodongInitiator}
                                    onInput={this.getHuodongInitiator}
                                />
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row justify="start" style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>咨询电话：
                            </Col>
                            <Col>
                                <Input
                                    placeholder={'请输入'}
                                    value={(this.state as any).huodongInitiatorPhone}
                                    onInput={this.getHuodongInitiatorPhone}
                                />
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row justify="start" align={'middle'} style={{alignItems: 'center'}}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>报名截止时间：
                            </Col>
                            <Col>
                                <label onClick={this.end}>
                                    <Radio name="radioOptionsExample" checked={(this.state as any).isHidde}>
                                        活动结束前
                                    </Radio>
                                </label>
                                <label onClick={this.zidingTime}>
                                    <Radio name="radioOptionsExample" checked={(this.state as any).Customizing}>
                                        自定义时间
                                    </Radio>
                                </label>
                            </Col>
                            <Col>
                                {!(this.state as any).Customizing ? (
                                    ''
                                ) : (this.state as any).huodongJiezhiDate === '' ||
                                (this.state as any).huodongJiezhiDate === null ||
                                (this.state as any).huodongJiezhiDate === undefined ? (
                                    <DatePicker
                                        showTime={true}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder="请选择时间"
                                        onChange={this.getHuodongJiezhiDate}
                                    />
                                ) : (
                                    <DatePicker
                                        showTime={true}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder="请选择时间"
                                        onChange={this.getHuodongJiezhiDate}
                                        value={moment((this.state as any).huodongJiezhiDate, 'YYYY-MM-DD HH:mm:ss')}
                                    />
                                )}
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row align={'middle'}>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>票种类型：
                            </Col>
                            <Col span={20}>
                                {/*<table className="table table-fixed">*/}
                                {/*    <tr>*/}
                                {/*        <th>类型</th>*/}
                                {/*        <th>金额</th>*/}
                                {/*        <th>名额</th>*/}
                                {/*        <th>描述</th>*/}
                                {/*        <th>审核(是? 否)</th>*/}
                                {/*        <th>操作</th>*/}
                                {/*    </tr>*/}
                                {(this.state as any).ticketData.map((item: any, index: any) => (
                                    <Row gutter={8} align={'middle'} style={{marginTop: index === 0 ? 0 : 10}}>
                                        <Col> {this.selectType('100%', 30, index, item)}</Col>
                                        <Col> {this.inputDateTime('100%', 30, index, 'text', 'name', '名称')}</Col>
                                        <Col> {this.inputDateTime('100%', 30, index, 'number', 'money', '金额')}</Col>
                                        <Col> {this.inputDateTime('100%', 30, index, 'number', 'number', '名额')}</Col>
                                        <Col> {this.inputDateTime('100%', 30, index, 'text', 'detail', '描述')}</Col>
                                        <Col> {this.dropDownShenhe('100%', 30, index)}</Col>
                                        <Col>
                                            {' '}
                                            <Button style={{marginTop: 10}} onClick={() => this.delTicket(index)}>
                                                删除
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                                {/*</table>*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={3}/>
                            <Col>
                                <Button type={'primary'} onClick={this.addTicket}>
                                    添加票种类型
                                </Button>
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row type="flex" justify="start">
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>详情：
                            </Col>
                            <Col span={20}>
                                <WangEditor
                                    key={this.state.huodongXiangqing}
                                    value1={this.state.huodongXiangqing}
                                    changeCallback={(res: any) => this.wEditorValue(res)}
                                />
                            </Col>
                        </Row>
                        {/*<br/>*/}
                        <Row>
                            <Col span={3} style={{textAlign: 'right'}}>
                                <span style={{color: 'red'}}>*</span>报名信息：
                            </Col>
                            <Col span={15}>
                                {/*获得所有组件*/}
                                {(this.state as any).ziduan.map((item: any, index: any) => (
                                    //判断是否添加审核字段，如果是审核字段直接添加审核的组件
                                    <Row justify="start" style={{alignItems: 'center', marginTop: 0}}>
                                        <Col span={15}>
                                            {this.wid.selectWidget(
                                                '',
                                                item.ziduan_type,
                                                '100%',
                                                35,
                                                item.ziduan_name,
                                                item.ziduan_miaoshu,
                                                [],
                                                item.uuid || index,
                                                item.is_required ? 'required' : '',
                                                item.morenzhi,
                                                false,
                                                '',
                                                item.aliasName,
                                                '',
                                                false,
                                                true
                                            )}
                                        </Col>
                                        <Col span={2} style={{textAlign: 'right', marginLeft: 4}}>
                                            <Button
                                                key={index}
                                                style={{marginTop: 10, color: 'red'}}
                                                id={index}
                                                onClick={this.deleteZiduan}
                                            >
                                                删除
                                            </Button>
                                        </Col>
                                        <Col span={2} style={{textAlign: 'right', marginLeft: 4}}>
                                            <Button
                                                key={index}
                                                onClick={() => {
                                                    this.updataZiduan(index);
                                                }}
                                                style={{marginTop: 10, color: '#0288d1'}}
                                                id={index}
                                            >
                                                更新
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <Row justify="start">
                            <Col span={3} style={{textAlign: 'right'}}/>
                            <Col span={10}>
                                <Button
                                    type={'primary'}
                                    onMouseDown={this.addWidget}
                                    hidden={(this.state as any).addWidgetState}
                                >
                                    添加组件
                                </Button>
                            </Col>
                        </Row>
                        {/*<br/>*/}

                        <br/>
                        <br/>
                    </div>

                    <Modal
                        title="选择组件"
                        visible={(this.state as any).select}
                        footer={null}
                        onCancel={() => {
                            this.setState({select: false, addWidgetState: false});
                        }}
                        zIndex={99999}
                    >
                        <Row justify="center" align={'middle'}>
                            <Col span={18}>
                                <WidgetComponents clickCallback={(res: any) => this.selectButton(res)}/>
                            </Col>
                        </Row>
                    </Modal>

                    <Modal
                        width={1040}
                        zIndex={99999}
                        visible={(this.state as any).widgetVisible}
                        title={(this.state as any).widgetText}
                        onOk={this.confirmWidget}
                        onCancel={this.cancelAddConfirmWidget}
                        footer={[
                            <Button key="back" onClick={this.cancelAddConfirmWidget}>
                                取消
                            </Button>,
                            <Button key="submit" type="primary" onClick={this.confirmWidget}>
                                保存
                            </Button>,
                        ]}
                    >
                        <div>
                            <Row gutter={8} align={'middle'}>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    名字:
                                </Col>
                                <Col>
                                    <Input
                                        className="div-width"
                                        value={(this.state as any).widgetName}
                                        onInput={this.getWidgetName}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    描述:
                                </Col>
                                <Col>
                                    <Input.TextArea
                                        key={this.state.descr}
                                        value={this.state.descr}
                                        onChange={(value) => {
                                            this.setState({descr: value.target.value});
                                        }}
                                    ></Input.TextArea>
                                    {/*<LiuchengWangEditor*/}
                                    {/*	key={this.state.descr}*/}
                                    {/*	value={this.state.descr}*/}
                                    {/*	changeCallback={(res: any) => {*/}
                                    {/*		this.setState({ descr: res });*/}
                                    {/*	}}*/}
                                    {/*/>*/}
                                </Col>
                            </Row>

                            {/*这里判断是否是单选或者多选按钮*/}
                            {(this.state as any).widgetType === 'radio' ||
                            (this.state as any).widgetType === 'checkbox' ? (
                                <Row gutter={8} align={'middle'}>
                                    <Col span={4} style={{textAlign: 'right', marginTop: 5}}>
                                        选项:
                                    </Col>
                                    <Col>
                                        {(this.state as any).selectButtonNumber.map((item: any, index: any) => (
                                            <Row style={{marginTop: 0}}>
                                                <Col>
                                                    <Input
                                                        style={{width: 185}}
                                                        onInput={this.selectButtonValue}
                                                        id={index}
                                                        value={item}
                                                    />{' '}
                                                    <Button
                                                        id={index}
                                                        onClick={this.deleteSelectButton}
                                                        style={{marginLeft: 30, marginTop: 10}}
                                                    >
                                                        删除
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Row>
                                            <Col>
                                                <Button onClick={this.addSelectItem}>添加选项</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) : (
                                <Row gutter={8} align={'middle'}>
                                    <Col span={4} style={{textAlign: 'right'}}>
                                        默认值：
                                    </Col>
                                    <Col>
                                        <Input value={(this.state as any).morenzhi} onInput={this.morenzhi}/>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={8} align="middle">
                                <Col span={4} style={{textAlign: 'right'}}>
                                    是否必填：
                                </Col>
                                <Col>
                                    <Checkbox
                                        checked={(this.state as any).shifoubixuan}
                                        value={(this.state as any).shifoubixuan}
                                        onChange={this.shifoubixuan}
                                    />
                                </Col>
                            </Row>

                            <Row hidden={(this.state as any).widgetType !== 'dateTime'}>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    <div>是否允许更改：</div>
                                </Col>
                                <Col>
                                    <Checkbox
                                        checked={(this.state as any).allow_edit}
                                        defaultChecked={(this.state as any).allow_edit}
                                        onChange={this.isUpdataValue}
                                    />
                                </Col>
                            </Row>

                            <Row hidden={(this.state as any).widgetType !== 'date'}>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    是否允许更改：
                                </Col>
                                <Col>
                                    <Checkbox
                                        checked={(this.state as any).allow_edit}
                                        defaultChecked={(this.state as any).allow_edit}
                                        onChange={this.isUpdataValue}
                                    />
                                </Col>
                            </Row>

                            <Row hidden={(this.state as any).widgetType !== 'dateTime'}>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    是否默认当前时间：
                                </Col>
                                <Col>
                                    <Checkbox
                                        checked={(this.state as any).is_newDate}
                                        defaultChecked={(this.state as any).is_newDate}
                                        onChange={this.is_newDate}
                                    />
                                </Col>
                            </Row>

                            <Row className="row" hidden={(this.state as any).widgetType !== 'date'}>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    <div>是否默认当前时间：</div>
                                </Col>
                                <Col>
                                    <Checkbox
                                        checked={(this.state as any).is_newDate}
                                        defaultChecked={(this.state as any).is_newDate}
                                        onChange={this.is_newDate}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default AddHuodong;
